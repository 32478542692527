import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { useDarkMode } from "../../../Context/DarkModeContext";

function DatosTrans() {
  const datosTransferencia = `
        Importadora scarfran spa
        76703913-1
        Banco de Chile
        Cuenta Corriente
        1681274704
        transferencias@onloop.cl
    `;

  const [copiado, setCopiado] = useState(false);
  const [mostrarSiguiente, setMostrarSiguiente] = useState(false);
  const navigate = useNavigate();

  const { isDarkMode } = useDarkMode(); //  el estado del modo oscuro

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]); // Este useEffect solo se ejecutará cuando `isDarkMode` cambie

  useEffect(() => {
    const timer = setTimeout(() => {
      setMostrarSiguiente(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(datosTransferencia.trim());
    setCopiado(true);
    setMostrarSiguiente(true);
    setTimeout(() => setCopiado(false), 2000);
  };

  const handleNext = () => {
    navigate("/aprobado-transferencia");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isDarkMode ? "#878787" : "#000",
      }}
      className="datosTransferenciaBox"
    >
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          borderRadius: "10px",
          textAlign: "center",
          maxWidth: "400px",
          backgroundColor: isDarkMode ? "#000" : "#fff",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          className={"datosTransferencia"}
          gutterBottom
          sx={{ fontWeight: 700, fontFamily: "Outfit, sans-serif", color: isDarkMode ? "white" : "black"}}
        >
          ¡Tu compra está casi lista!
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={"datosTransferencia"}
          sx={{ mt: 2, fontWeight: 400, fontFamily: "Outfit, sans-serif" }}
        >
          Ahora completa la transferencia con los siguientes datos
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={"datosTransferencia"}
          sx={{
            mt: 1,
            whiteSpace: "pre-wrap",
            fontWeight: 400,
            fontFamily: "Outfit, sans-serif",
          }}
        >
          Importadora Scarfran SpA
          <br />
          76703913-1
          <br />
          Banco de Chile
          <br />
          Cuenta Corriente N° 1681274704
          <br />
          transferencias@onloop.cl
        </Typography>

        <Tooltip title={copiado ? "¡Copiado!" : "Copiar datos"}>
          <IconButton onClick={handleCopy} sx={{ mt: 2 }}>
            <ContentCopyIcon sx={{color: isDarkMode ? "white" : "black"}} />
            <Typography
              className={"datosTransferencia"}
              variant="body2"
              sx={{ ml: 1, fontWeight: 400, fontFamily: "Outfit, sans-serif", marginRight: isDarkMode ? "2em" : "3px" }}
            >
              Copiar datos
            </Typography>
          </IconButton>
        </Tooltip>

        {mostrarSiguiente && (
          <Button
            variant="contained"
            sx={{
              mt: 3,
              fontWeight: 400,
              fontFamily: "Outfit, sans-serif",
              backgroundColor: "#ff4d4d",
              "&:hover": { backgroundColor: "#e03e3e" },
            }}
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}
      </Paper>
    </Box>
  );
}

export default DatosTrans;
