import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useDarkMode } from "../../../Context/DarkModeContext";

function Aprobado() {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const effectRan = useRef(false);
  const { isDarkMode } = useDarkMode();

  // Aplicar o quitar la clase dark-mode en el body según isDarkMode
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true; // Se establece aquí, una sola vez

    // Validar parámetros de la URL para Mercado Pago
    const searchParams = new URLSearchParams(location.search);
    const collectionStatus = searchParams.get("collection_status");
    const medioPagoQuery = searchParams.get("medio_pago")?.toLowerCase();

    if (medioPagoQuery === "mercadopago" && collectionStatus !== "approved") {
      navigate("/");
      return;
    }

    // Obtener paymentData desde sessionStorage
    const storedPaymentData = sessionStorage.getItem("paymentData");
    if (!storedPaymentData) {
      // console.error("paymentData no encontrado en sessionStorage");
      setTimeout(navigate, 3000, "/");
      return;
    }

    let parsedData;
    try {
      parsedData = JSON.parse(storedPaymentData);
    } catch (error) {
      // console.error("Error al parsear paymentData:", error);
      navigate("/");
      return;
    }

    // Función para enviar datos al backend
    const enviarDatosAlBackend = async (url, paymentData) => {
      const productos = paymentData.productos.map((producto) => {
        let costeAdicionalModificado = producto.coste_adicional;
        if (producto.cantidad === 1 && producto.coste_adicional > 0) {
          costeAdicionalModificado = 0;
        } else if (producto.cantidad >= 2 && producto.coste_adicional > 0) {
          costeAdicionalModificado = (producto.cantidad - 1) * 2000;
        }
        return {
          id_producto: producto.id,
          titulo: producto.titulo,
          precio: producto.precio,
          cantidad: producto.cantidad,
          coste_adicional: costeAdicionalModificado,
        };
      });

      const datosEnviados = {
        compra_id: paymentData.compra_id,
        nombre: paymentData.nombre,
        rut: paymentData.rut,
        telefono: paymentData.telefono,
        correo: paymentData.correo,
        precio_total: paymentData.precio_total || 0,
        precio_comuna: paymentData.precio_comuna || 0,
        total_pagar: paymentData.total_pagar || 0,
        medio_pago: paymentData.medioPago || "",
        productos: JSON.stringify(productos),
        update_stock: true,
        envio: paymentData.envio || "",
        ...(paymentData.envio !== "Retiro" && {
          direccion: paymentData.direccion || "",
          comuna: paymentData.comuna || "",
          region: paymentData.region || "",
        }),
        ...(paymentData.facturacion && {
          facturacion: paymentData.facturacion,
          rut_empresa: paymentData.rutEmpresa || "",
          razon_social: paymentData.razonSocial || "",
          giro: paymentData.giro || "",
          direccion_empresa: paymentData.direccionEmpresa || "",
        }),
      };

      // console.log(`Enviando datos a ${url}:`, datosEnviados);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(datosEnviados),
        });

        if (!response.ok) {
          const errorText = await response.text();
          // console.error(`Error en la solicitud a ${url}:`, response.statusText, errorText);
          throw new Error("Error en la solicitud: " + response.statusText);
        }

        const result = await response.json();
        // console.log(`Respuesta de ${url}:`, result);

        if (result.success) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null });
          
          const purchaseData = {
            event: "purchase",
            ecommerce: {
              transaction_id: parsedData.compra_id,
              value: parsedData.total_pagar,
              shipping: parsedData.envio === 'Retiro' ? 0 : (parsedData.precio_comuna || 0),
              currency: "CLP",
              email: parsedData.correo,
              items: parsedData.productos.map((producto, index) => ({
                item_id: producto.id,
                item_name: producto.titulo,
                price: producto.precio,
                quantity: producto.cantidad,
              })),
            }
          };
        
          // console.log("Enviando evento purchase:", purchaseData);
          window.dataLayer.push(purchaseData); 
          setSuccess(true);
          return result;
        }  else {
          throw new Error(result.error || "Error desconocido");
        }
      } catch (error) {
        // console.error(`Error en la solicitud a ${url}:`, error.message);
        throw error;
      }
    };

    // Enviar datos para actualizar la compra
    enviarDatosAlBackend("https://onloop.cl/api/actualizar_compra.php", parsedData)
      .then(async () => {
        try {
          // Se utiliza siempre comprobante_completo.php (comprobante_incompleto queda obsoleto)
          const comprobanteUrl = "https://onloop.cl/api/comprobante_completo.php";
          // console.log("Enviando datos al comprobante:", comprobanteUrl);
          // console.log("Datos:", parsedData);
          await enviarDatosAlBackend(comprobanteUrl, parsedData);
          // console.log("Comprobante enviado exitosamente");
        } catch (error) {
          // console.error("Error al enviar los datos al backend de comprobante:", error.message);
        }
      })
      // .catch((error) => console.error("Error en el proceso de compra:", error));

    sessionStorage.removeItem("paymentData");
    setTimeout(navigate, 5000, "/");
  }, [location, navigate]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isDarkMode ? "grey" : "#f4f4f4",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "30px",
          borderRadius: "10px",
          textAlign: "center",
          maxWidth: "400px",
          backgroundColor: isDarkMode ? "black" : "#fff",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: isDarkMode ? "white" : "black" }}>
          ¡Compra Exitosa!
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 2, color: isDarkMode ? "white" : "black" }} gutterBottom>
          Su compra ha sido procesada exitosamente.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1, color: isDarkMode ? "white" : "black" }} gutterBottom>
          En breve recibirá un comprobante en su correo.
        </Typography>
        {success && (
          <Typography variant="body1" component="p" sx={{ mt: 2, color: "green" }}>
            Regresarás a la página de inicio en unos momentos...
          </Typography>
        )}
      </Paper>
    </Box>
  );
}

export default Aprobado;