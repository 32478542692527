import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

const Resumen = ({ 
    carrito, 
    precioTotal, 
    costoEnvio, 
    nombre, 
    rut, 
    telefono, 
    correo, 
    direccion, 
    selectedComuna,
    selectedRegion,
    selectedRegionId,
    rutEmpresa,  
    razonSocial,
    giro,
    direccionEmpresa,
    envio,
    facturacion,
    compra_id
}) => {

    // Formateador de moneda chilena
    const formatoCLP = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
    });

    // Se asume que precioTotal es una función que retorna un número
    const totalCompra = typeof precioTotal === 'function' ? precioTotal() : 0;

    // Se calcula el costo adicional total convirtiendo los valores a números
    const costeAdicionalTotal = carrito.reduce((total, producto) => {
        // Si el envío es "Retiro", se fuerza el costo adicional a 0
        let costeAdicionalModificado = envio === 'Retiro'
          ? 0
          : Number(producto.coste_adicional) || 0;
    
        // Solo se aplica la lógica si el envío NO es "Retiro" y la región es 7
        if (envio !== 'Retiro' && Number(selectedRegionId) === 7) {
            if (costeAdicionalModificado !== 0) {
                if (Number(producto.cantidad) < 2 && costeAdicionalModificado === 2000) {
                    costeAdicionalModificado = 0;
                } else if (Number(producto.cantidad) >= 2) {
                    costeAdicionalModificado = (Number(producto.cantidad) - 1) * 2000;
                }
            }
        }
        return total + costeAdicionalModificado;
    }, 0);    

    // Convertir costoEnvio a número (asegurarse de que costoEnvio venga en un formato numérico correcto)
    const costoEnvioNumero = Number(costoEnvio) || 0;

    // Precio total de envío suma el costo de envío base y el costo adicional total
    const precioTotalEnvio = costoEnvioNumero + costeAdicionalTotal;

    // Total a pagar es la suma de la compra y el envío
    const totalAPagar = totalCompra + precioTotalEnvio;

    return (
        <Box sx={{ padding: { xs: 1, sm: 2 }, maxWidth: '800px', margin: 'auto' }}>
                {carrito.map((producto) => {
                    // Si el envío es "Retiro", se fuerza el costo adicional a 0
                    let costeAdicionalModificado = envio === 'Retiro'
                    ? 0
                    : Number(producto.coste_adicional) || 0;
                    
                    if (envio !== 'Retiro' && Number(selectedRegionId) === 7) {
                        if (costeAdicionalModificado !== 0) {
                            if (Number(producto.cantidad) < 2 && costeAdicionalModificado === 2000) {
                                costeAdicionalModificado = 0;
                            } else if (Number(producto.cantidad) >= 2) {
                                costeAdicionalModificado = (Number(producto.cantidad) - 1) * 2000;
                            }
                        }
                    }

                return (
                    <Box key={producto.id} sx={{ marginBottom: 2 }}>
                        <Typography 
                            variant="subtitle1"
                            sx={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 700,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }}
                        >
                            Productos
                        </Typography>
                        <Typography 
                            variant="h6" 
                            sx={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }}
                        >
                            {producto.titulo}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }}
                        >
                            Precio unitario: {formatoCLP.format(producto.precio)}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }}
                        >
                            Precio total: {formatoCLP.format(producto.precio * producto.cantidad)}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }}
                        >
                            Cantidad: {producto.cantidad}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }}
                        >
                            Costo adicional de envio: {formatoCLP.format(costeAdicionalModificado)}
                        </Typography>
                        <Divider sx={{ marginTop: 2 }} />
                    </Box>
                );
            })}
            <Typography 
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 700,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Datos personales
            </Typography>
            <Typography 
                variant="subtitle1" 
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Id de compra: {compra_id}
            </Typography>
            <Typography 
                variant="subtitle1" 
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Nombre: {nombre}
            </Typography>
            <Typography 
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Rut: {rut}
            </Typography>
            <Typography 
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Teléfono: {telefono}
            </Typography>
            <Typography 
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Correo: {correo}
            </Typography>
            <Typography 
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                Metodo de envio: {envio}
            </Typography>
            {envio !== 'Retiro' && (
            <>
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Dirección: {direccion}
                </Typography>
                
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Región: {selectedRegion}
                </Typography>
                
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Comuna: {selectedComuna}
                </Typography>
            </>
            )}
            {facturacion && (
                <>
                <Divider sx={{ marginTop: 2 }} />
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 700,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Datos de factura
                </Typography>
                
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Rut Empresa: {rutEmpresa}
                </Typography>
                
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Razón Social: {razonSocial}
                </Typography>
                
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Giro: {giro}
                </Typography>
                
                <Typography
                variant="subtitle1"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                Dirección Empresa: {direccionEmpresa}
                </Typography>
            </>
            )}
            
            <Divider sx={{ marginTop: 2 }} />
            {precioTotalEnvio !== 0 || Number(selectedRegionId) === 7 ? (
                <>
                    <Typography 
                        variant="subtitle1"
                        sx={{
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        }}
                    >
                        Costo de envío: {costoEnvioNumero === 0 && selectedRegion !== 'Metropolitana de Santiago' ? "Deberás negociarlo al finalizar tu compra" : formatoCLP.format(costoEnvioNumero)}
                    </Typography>
                    <Typography 
                        variant="subtitle1"
                        sx={{
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        }}
                    >
                        Costo adicional de envío total: {formatoCLP.format(costeAdicionalTotal)}
                    </Typography>
                </>
            ) : null}
            <Typography 
                variant="h5"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                <b>
                    {envio === 'Retiro'
                        ? `Sub-total de envío: ${formatoCLP.format(precioTotalEnvio)}`
                        : selectedRegion !== 'Metropolitana de Santiago'
                        ? "El costo de envío será negociado con el equipo de Onloop dependiendo de dónde se necesite el producto"
                        : `Sub-total de envío: ${formatoCLP.format(precioTotalEnvio)}`
                    }
                </b>
            </Typography>
            {envio === 'Retiro' || selectedRegion === 'Metropolitana de Santiago' ? (
                <Typography 
                    variant="h5"
                    sx={{
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 700,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}
                >
                    <b>Precio de compra: {formatoCLP.format(totalCompra)}</b>
                </Typography>
            ) : (
                <br />
            )}
            <Typography 
                variant="h5"
                sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 700,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
            >
                <b>Precio total: {formatoCLP.format(totalAPagar)}</b>
            </Typography>
        </Box>
    );
};

export default Resumen;