import React, { useEffect } from "react";
import { useDarkMode } from "../Context/DarkModeContext";
import OnloopDark from "../Image/luna.svg"; 
import OnloopLight from "../Image/sol.svg"; 
import "./Styles/SinComponente/SwitchButton.css";

const SwitchButton = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
      sessionStorage.setItem("darkMode", "enabled");
    } else {
      document.body.classList.remove("dark-mode");
      sessionStorage.setItem("darkMode", "disabled");
    }
  }, [isDarkMode]);

  return (
    <div className="switch-icon" onClick={toggleDarkMode}>
      <img
        src={isDarkMode ? OnloopDark : OnloopLight}
        alt="Modo oscuro/claro"
        className="switch-icon-img"
      />
    </div>
  );
};

export default SwitchButton;
