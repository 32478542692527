import React, { useEffect, useState } from "react";
import axios from "axios";
import ItemList from "./ItemList";
import SearchBar from "./SearchBar";
import { Box, Typography, Pagination, CircularProgress } from "@mui/material";
import fotoCategoria from "../../Image/Banners/productobanner.jpg";
import styled from "styled-components";
import Grid2 from "@mui/material/Unstable_Grid2";

import { ReactComponent as SilleriaIcon } from "../../Image/categorias/sillería.svg";
import { ReactComponent as AlmacenajeIcon } from "../../Image/categorias/almacenaje.svg";
import { ReactComponent as BasurerosIcon } from "../../Image/categorias/basureros.svg";
import { ReactComponent as EscaleraIcon } from "../../Image/categorias/escalera.svg";
import { ReactComponent as EscritoriosIcon } from "../../Image/categorias/escritorios.svg";
import { ReactComponent as JuguetesIcon } from "../../Image/categorias/juguetes.svg";
import { ReactComponent as KardexIcon } from "../../Image/categorias/kardex.svg";
import { ReactComponent as LockerIcon } from "../../Image/categorias/locker.svg";
import { ReactComponent as MascotasIcon } from "../../Image/categorias/mascotas.svg";
import { ReactComponent as PapeleriaIcon } from "../../Image/categorias/papeleria.svg";

import '../Styles/Compra/ItemListContainer.css';

const Container = styled.div`
  .section-banner-producto {
    position: relative;
    width: 100%;
  }

  .section-banner-producto img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-banner-producto h1 {
    margin: 0; /* Eliminar márgenes predeterminados */
    font-family: "Outfit", sans-serif;
    font-weight: bold;
    font-size: 45px;
    color: white;
  }
`;

const CategoryButton = ({ id, selected, handleClick, title, image }) => {
  // Leer el estado del modo desde sessionStorage
  const [isChecked, setIsChecked] = useState(() => {
    const savedMode = sessionStorage.getItem("darkMode");
    return savedMode === "enabled"; // "enabled" indica el modo oscuro
  });

  // Establecer el color del fondo en función de la selección y el modo
  const getBackgroundColor = () => {
    if (selected) {
      return isChecked ? "#B63232" : "#B63232"; // Celeste en modo oscuro, rojo en modo normal
    } else {
      return isChecked ? "white" : "white"; // Negro en modo oscuro, blanco en modo normal
    }
  };

  // Establecer el color del texto en función de la selección y el modo
  const getTextColor = () => {
    return selected ? "white" : isChecked ? "black" : "black";
  }

  const getIconColor = () => {
    return selected ? "white" : isChecked ? "#B63232" : "#B63232";
  };

  let IconComponent = React.Fragment;
  if (title === "Silleria") {
    IconComponent = SilleriaIcon;
  }
  if (title === "Almacenaje") {
    IconComponent = AlmacenajeIcon;
  }
  if (title === "Basureros") {
    IconComponent = BasurerosIcon;
  }
  if (title === "Escalera") {
    IconComponent = EscaleraIcon;
  }
  if (title === "Escritorios") {
    IconComponent = EscritoriosIcon;
  }
  if (title === "Juguetes") {
    IconComponent = JuguetesIcon;
  }
  if (title === "Kardex") {
    IconComponent = KardexIcon;
  }
  if (title === "Lockers") {
    IconComponent = LockerIcon;
  }
  if (title === "Mascotas") {
    IconComponent = MascotasIcon;
  }
  if (title === "Papelería") {
    IconComponent = PapeleriaIcon;
  }

  return (
    <div
      onClick={(e) => {
        handleClick(id, selected);
      }}
      style={{
        cursor: "pointer",
        padding: "0.8rem",
        height: "3rem",
        border: "1px solid #b63232",
        borderRadius: "0.3rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: getBackgroundColor(),
      }}
    >
      <div
        style={{
          width: "10rem",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          placeContent: "center",
          gap: "1rem",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <h2
          style={{
            color: getTextColor(),
            fontFamily: "Outfit, sans-serif",
            fontWeight: "bold",
            fontSize: "1rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: "100%",
          }}
        >
          {title}
        </h2>
        <IconComponent
          style={{
            width: "1.5rem",
            height: "1.5rem",
            color: getIconColor(),
          }}
        />
      </div>
    </div>
  );
};

const ItemListContainer = () => {
  const [productos, setProductos] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hideCategories, setHideCategories] = useState(true);

  const itemsPerPage = 12;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get("search");
    const categoryParam = urlParams.get("categories");
    const pageParam = urlParams.get("page");

    if (searchParam) setSearchText(searchParam);
    if (categoryParam) setSelectedCategories(categoryParam.split(","));
    if (pageParam) setPage(parseInt(pageParam));
  }, [setSearchText]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://onloop.cl/api/categorias.php"
        );
        if (response.data.success) {
          setCategories(response.data.categories.map((cat) => cat.trim()));
        }
      } catch (err) {
        // console.error("Error al obtener las categorías:", err.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (
      selectedCategories === "" &&
      new URLSearchParams(window.location.search).get("categories")
    ) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchProductos = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          "https://onloop.cl/api/productos.php",
          {
            query: searchText,
            category:
              selectedCategories.length > 0
                ? selectedCategories.join(",")
                : null,
            sort: sortOption,
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
            orderBy:
              sortOption !== "default"
                ? sortOption.includes("ASC")
                  ? sortOption.slice(0, -3)
                  : sortOption.slice(0, -4)
                : null,
            order:
              sortOption !== "default"
                ? sortOption.includes("ASC")
                  ? "ASC"
                  : "DESC"
                : null,
          },
          { signal }
        );

        setLoading(false);

        if (response.data.success) {
          setProductos(response.data.data);
          // console.log(response.data.data);
          setTotal(response.data.count);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // console.log('Solicitud cancelada:', err.message);
        } else {
          setError("Error al obtener los productos: " + err.message);
        }
      }
    };

    fetchProductos();

    return () => controller.abort();
  }, [page, searchText, sortOption, selectedCategories, selectedBrands]);

  const handleCategoryChange = (value, remove = true) => {
    if (remove) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }

    if (remove) {
      const url = new URL(window.location);
      const categories = url.searchParams.get("categories").split(",");
      const newCategories = categories.filter((cat) => cat !== value);
      url.searchParams.set("categories", newCategories.join(","));
      window.history.pushState({}, "", url);
    } else {
      const url = new URL(window.location);
      url.searchParams.set(
        "categories",
        [...selectedCategories, value].join(",")
      );
      window.history.pushState({}, "", url);
    }
    /* handlePageChange(1); */
  };

  const handlePageChange = (value) => {
    const url = new URL(window.location);
    url.searchParams.set("page", value);
    window.history.pushState({}, "", url);

    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedBrands((prevSelectedBrands) =>
      prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter((b) => b !== brand)
        : [...prevSelectedBrands, brand]
    );
  };

  return (
    <Container>
      <Box>
        <div className="section-banner-producto">
          <div
            className="overlay-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "50px",
                color: "white",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              {selectedCategories.length >= 1
                ? selectedCategories.join(", ")
                : "Todos los Productos"}
            </h1>
          </div>
          <img src={fotoCategoria} alt="foto categoria" />
        </div>
        {!hideCategories && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid2
              container
              spacing={1}
              sx={{
                marginTop: "2rem",
                justifyContent: "center",
                marginLeft: "6rem",
                marginRight: "6rem",
                maxWidth: "1000px",
              }}
            >
              {categories.map((category, index) => (
                <Grid2 item key={index}>
                  <CategoryButton
                    className={"categoryButton"}
                    id={category}
                    selected={selectedCategories.includes(category)}
                    handleClick={handleCategoryChange}
                    title={category}
                    image={"https://picsum.photos/200/300"}
                  />
                </Grid2>
              ))}
            </Grid2>
          </div>
        )}
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          sortOption={sortOption}
          setSortOption={setSortOption}
          selectedBrands={selectedBrands}
          handleBrandChange={handleBrandChange}
          categories={categories}
          hideCategories={hideCategories}
          setHideCategories={setHideCategories}
        />
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Outfit, sans-serif",
            margin: "2rem",
          }}
        ></Typography>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <>
          {error && (
            <Typography
              color="error"
              variant="h6"
              component="p"
              sx={{ textAlign: "center" }}
            >
              {error}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              justifyContent: "center",
            }}
          >
            <ItemList productos={productos} page={page} />
          </Box>
          {total > itemsPerPage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Pagination
                count={Math.ceil(total / itemsPerPage)}
                page={page}
                onChange={(event, value) => handlePageChange(value)}
                variant="outlined"
                color="primary"
              />
            </Box>
          )}
        </>
      </Box>
    </Container>
  );
};

export default ItemListContainer;
