import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Snackbar, Button } from '@mui/material';
import ItemCount from './Itemcount';
import { toCapital } from '../../Helpers/toCapital';
import { CartContext } from '../../Context/CardContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import '../Styles/Inicio/Inicio.css';
import ProductosVariantes from './ProductosVariantes';
import CarrucelProductos from './CarucelProductos';

const FALLBACK_IMAGE = 'https://onloop.cl/images/Imagen_no_disponible.jpg';

const ItemDetail = ({ item }) => {
  const [cantidad, setCantidad] = useState(1);
  const [agregadoAlCarrito, setAgregadoAlCarrito] = useState(false);
  const [errorStock, setErrorStock] = useState(false);
  const { agregarAlCarrito } = useContext(CartContext);
  const [imagenPrincipal, setImagenPrincipal] = useState(item.imagen);
  const [miniStart, setMiniStart] = useState(0);
  const [pdfExists, setPdfExists] = useState(false);

  // Arreglo de miniaturas (imagen principal y adicionales)
  const miniaturas = [
    { id: 'principal', imagen: item.imagen },
    ...(item.miniaturas || []),
  ];

  // Construir el enlace al PDF usando el SKU (item.modelo)
  // Se elimina la extensión .pdf si ya existe y se le añade ".pdf"
  const pdfFileUrl = `/Pdf/${item.modelo.replace(/\.pdf$/i, '')}.pdf`;

  // Verificar si el PDF existe, comprobando además que su Content-Type sea de tipo PDF.
  useEffect(() => {
    const checkPdfExistence = async () => {
      try {
        const response = await fetch(pdfFileUrl, { method: 'HEAD' });
        if (response.ok) {
          const contentType = response.headers.get('Content-Type') || '';
          setPdfExists(contentType.toLowerCase().includes('pdf'));
        } else {
          setPdfExists(false);
        }
      } catch (error) {
        setPdfExists(false);
      }
    };
    checkPdfExistence();
  }, [pdfFileUrl]);

  // Funciones para mover el scroll de miniaturas.
  const handlePrev = () => {
    setMiniStart((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setMiniStart((prev) => Math.min(prev + 1, miniaturas.length - 4));
  };

  const handleRestar = () => {
    if (cantidad > 1) setCantidad(cantidad - 1);
  };

  const handleSumar = () => {
    if (cantidad < item.stock) {
      setCantidad(cantidad + 1);
    }
  };

  // Formato de precio en CLP
  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP',
      maximumFractionDigits: 0,
    });
  };

  const handleAgregarAlCarrito = () => {
    const resultado = agregarAlCarrito(item, cantidad);
    if (resultado.success) {
      setAgregadoAlCarrito(true);
      setTimeout(() => {
        setAgregadoAlCarrito(false);
      }, 3000);
    } else {
      setErrorStock(true);
      setTimeout(() => {
        setErrorStock(false);
      }, 3000);
    }
  };

  const handleImageError = () => {
    setImagenPrincipal(FALLBACK_IMAGE);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        padding: '1rem',
        margin: '4rem 0',
      }}
      className={'itemDetailBox'}
    >
      {/* PRIMERA FILA: Imagen + Información Principal */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '1rem',
        }}
      >
        {/* Sección de imagen principal y miniaturas */}
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
            <img
              src={imagenPrincipal}
              onError={handleImageError}
              alt={item.titulo}
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
          </Box>
          {miniaturas.length > 1 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                justifyContent: 'center',
                marginTop: '1rem',
                overflow: 'hidden',
              }}
            >
              {miniStart > 0 && (
                <Button onClick={handlePrev} sx={{ minWidth: 'auto' }}>
                  <ArrowBackIosIcon fontSize="small" />
                </Button>
              )}
              {miniaturas.slice(miniStart, miniStart + 4).map((miniatura) => (
                <img
                  key={miniatura.id}
                  src={miniatura.imagen}
                  alt={`Miniatura ${miniatura.id}`}
                  style={{
                    width: '50px',
                    height: '50px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    border: miniatura.imagen === imagenPrincipal ? '2px solid #000' : 'none',
                    borderRadius: '4px',
                  }}
                  onClick={() => setImagenPrincipal(miniatura.imagen)}
                  onError={(e) => (e.target.src = FALLBACK_IMAGE)}
                />
              ))}
              {miniStart + 4 < miniaturas.length && (
                <Button onClick={handleNext} sx={{ minWidth: 'auto' }}>
                  <ArrowForwardIosIcon fontSize="small" />
                </Button>
              )}
            </Box>
          )}
        </Box>

        {/* Sección de información del producto */}
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          {/* Categoría y SKU */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem', marginBottom: '10px' }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
                fontSize: '0.875rem',
              }}
            >
              Categoría: {toCapital(item.categoria)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
                fontSize: '0.875rem',
              }}
            >
              SKU: {toCapital(item.modelo)}
            </Typography>
          </Box>

          {/* Título y precio */}
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.5rem',
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              marginBottom: '1rem',
            }}
          >
            {item.titulo}
          </Typography>
          <Typography variant="h5" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
            {formatPrice(item.precio)}
          </Typography>

          {/* Descripción general */}
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 400,
              fontSize: '1rem',
              textAlign: 'justify',
              lineHeight: 1.5,
              margin: 0,
              padding: '1rem 0',
            }}
            dangerouslySetInnerHTML={{ __html: item.descripcion }}
          />

          {/* Sección de variantes */}
          <Box sx={{ mt: '-2rem' }}>
            <ProductosVariantes variantes={item.variantes} />
          </Box>

          <Box sx={{ mt: '1rem' }}>
            {/* Contador para agregar al carrito */}
              <ItemCount
                cantidad={cantidad}
                handleSumar={handleSumar}
                handleRestar={handleRestar}
                handleAgregar={handleAgregarAlCarrito}
                stock={item.stock}
                costeAdicional={item.coste_adicional}
                categoria={item.categoria}
                pdfFileUrl={pdfExists ? pdfFileUrl : null}
              />

            {/* Snackbars para feedback al usuario */}
            <Snackbar
              open={agregadoAlCarrito}
              autoHideDuration={3000}
              onClose={() => setAgregadoAlCarrito(false)}
              message={`Se han agregado ${cantidad} ${item.titulo} al carrito.`}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
            <Snackbar
              open={errorStock}
              autoHideDuration={3000}
              onClose={() => setErrorStock(false)}
              message="No se puede añadir más de lo disponible en stock"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
          </Box>
        </Box>
      </Box>

      {/* SEGUNDA FILA: Descripción detallada y Medidas */}
      <Box
        sx={{
          marginTop: '3rem',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '2rem',
        }}
      >
        {/* Columna: Descripción detallada */}
        {item.descripcion_detalle && (
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              Descripción detallada
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
                fontSize: '1rem',
                textAlign: 'justify',
                lineHeight: 1.5,
              }}
              dangerouslySetInnerHTML={{ __html: item.descripcion_detalle }}
            />
          </Box>
        )}

        {/* Columna: Medidas */}
        {item.medidas && (
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              Medidas
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.5,
              }}
            >
              {toCapital(item.medidas)}
            </Typography>
          </Box>
        )}
      </Box>

      <Typography className="inicio-title" id="tePuedeInteresar">
        <h2>Te puede interesar</h2>
      </Typography>
      <CarrucelProductos />
    </Box>
  );
};

export default ItemDetail;