import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';
import defaultImage from '../Image/Blog/test1.jpg';
import image1 from '../Image/Blog/1.jpeg';
import image2 from '../Image/Blog/2.jpeg';
import image3 from '../Image/Blog/3.jpg';
import image4 from '../Image/Blog/4.jpg';
import image5 from '../Image/Blog/5.jpeg';

// Datos de los blogs
const blogData = {
  "sillas-ergonomicas": {
    title: "Sillas Ergonómicas: Comodidad y Durabilidad",
    text: "Elegir sillas cómodas y ergonómicas es crucial para mantener la comodidad y la salud durante largas horas de trabajo. Opta por sillas ergonómicas que ofrezcan altura regulable para personalizar la silla a tu postura y aumentar tu productividad. Los materiales duraderos, como la malla resistente, garantizan una larga vida útil, mientras que las bases de metal aseguran la estabilidad necesaria para un uso prolongado. Encuentra las mejores sillas para oficina y transforma tu espacio de trabajo con calidad y confort.",
    image: image1,
  },
  "escritorios-modernos": {
    title: "Escritorios Modernos: Escritorios que Inspiran",
    text: "Encuentra el escritorio perfecto para tu hogar u oficina con nuestra amplia gama de escritorios diseñados para satisfacer todas tus necesidades. Desde modelos estándar con cajones que ofrecen un diseño clásico y práctico, ideales para mantener tu espacio de trabajo ordenado, hasta escritorios de altura ajustable que promueven una postura saludable y permiten alternar entre sentado y de pie, perfectos para cualquier home office.  Al elegir el escritorio adecuado, mejorarás tu comodidad y productividad, ya sea que estés trabajando, estudiando o creando. Explora nuestra colección y transforma tu área de trabajo en un lugar cómodo y eficiente. ¡Descubre hoy mismo el escritorio ideal para ti!",
    image: image2,
  },
  "lockers-metal": {
    title: "Lockers de Metal: Estilo y Funcionalidad para Tu Espacio",
    text: "En la búsqueda de soluciones prácticas para la organización y el aprovechamiento del espacio, los lockers de metal se han convertido en una opción cada vez más popular tanto en entornos domésticos como en oficinas. Estos versátiles muebles de metal no solo aportan una estética moderna y profesional, sino que también ofrecen una serie de ventajas funcionales que pueden transformar la manera en que gestionamos nuestros espacios. Los lockers de metal de onloop, fabricados con metal de alta calidad, aseguran una durabilidad excepcional y una resistencia superior ante el desgaste, la humedad y los golpes.",
    image: image3,
  },
  "simulando-profesiones": {
    title: "Simulando Profesiones: Juguetes Educativos para Niños",
    text: "Los juguetes desempeñan un papel esencial en el desarrollo infantil. En particular, los juguetes que permiten a los niños simular profesiones como doctor, veterinario y cocinero no solo proporcionan entretenimiento, sino que también actúan como herramientas educativas clave. Estos juguetes promueven el crecimiento y el aprendizaje de los niños, fomentando habilidades importantes mientras juegan.",
    image: image4,
  },
  "autos-control-remoto": {
    title: "Autos a Control Remoto: Tecnología, Potencia y Entretenimiento",
    text: "¡Descubre la diversión de los autos a control remoto! Estos emocionantes vehículos ofrecen una experiencia única que combina velocidad y maniobrabilidad, perfectos para todas las edades. Desde competiciones con amigos hasta crear circuitos en casa, cada carrera con autos a control remoto es una nueva aventura llena de adrenalina. Con diversos colores y diseños, puedes elegir el auto a control remoto que mejor refleje tu personalidad. Los autos a control remoto no son solo juguetes; son una forma divertida de conectarte con familiares y amigos mientras disfrutas de la emoción de las carreras. ¡Es hora de encender tu motor y salir a la pista con tu auto a control remoto!",
    image: image5,
  },
  "ejercicio-aire-libre": {
    title: "Beneficios del Ejercicio al Aire Libre",
    text: "Los beneficios del ejercicio al aire libre: cómo aprovechar la naturaleza para mejorar tu bienestar físico y mental.",
    image: defaultImage,
  },
};

const BlogEntryContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 40px;
  text-align: left;
  position: relative; /* Para que el botón con position absolute se ancle aquí */
  
  h1 {
    font-size: 2.5rem;
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
    margin-top: 20px; /* para separarlo del botón */
    margin-bottom: 20px; /* espacio entre el título y el texto */
  }

  p {
    font-size: 1.2rem;
    font-family: 'Inter', serif;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
`;

const BlogEntry = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const blog = blogData[slug];

  if (!blog) {
    return <h1>Error: Blog no encontrado</h1>;
  }

  return (
    <BlogEntryContainer>
      <Button
        variant="contained"
        onClick={() => navigate('/blog')}
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          backgroundColor: '#B63232',
          color: 'white',
          '&:hover': {
            backgroundColor: '#922727',
          },
          textTransform: 'none',
        }}
      >
        Atrás
      </Button>

      <img src={blog.image} alt={blog.title} />
      <h1>{blog.title}</h1>
      <p>{blog.text}</p>
    </BlogEntryContainer>
  );
};

export default BlogEntry;