import React, { createContext, useState, useContext, useEffect } from "react";

// Crear el contexto
const DarkModeContext = createContext();

// Proveedor del contexto
export const DarkModeProvider = ({ children }) => {
  // Leer el estado guardado en localStorage (devuelve `true` si está activado, `false` si no)
  const storedDarkMode = localStorage.getItem("darkMode") === "enabled";

  // Estado del modo oscuro
  const [isDarkMode, setIsDarkMode] = useState(storedDarkMode);

  // Función para cambiar el modo oscuro y guardarlo en localStorage
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", newMode ? "enabled" : "disabled");
      return newMode;
    });
  };

  // useEffect para aplicar el modo oscuro al cargar la app
  useEffect(() => {
    if (storedDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [storedDarkMode]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useDarkMode = () => useContext(DarkModeContext);
