import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
.container {
    background-color: #b63232;
    color: white;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* section */
  .section {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .section span {
    font-family: "Outfit", serif;
  }
  
  /* icon */
  .icon {
    margin-right: 10px;
  }
`

const Datos = () => {
  return (
    <Container>
      <div className="container">
        <div className="section">
          <span>¡Sí, leíste bien! Todos nuestros precios incluyen IVA.</span>
        </div>
      </div>
    </Container>
  );
};

export default Datos;
