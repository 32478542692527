import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Carrucel = () => {
  const [items, setItems] = useState([]); 
  const [error, setError] = useState(null);    
  const [loading, setLoading] = useState(true);

  // Función para formatear el precio
  const formatPrice = (price) => {
    const numericPrice = Number(price);
    return isNaN(numericPrice)
      ? 'Precio no disponible'
      : numericPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  };

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        // Genera un número aleatorio entre 1 y 3
        const page = Math.floor(Math.random() * 3) + 1;
        const response = await axios.post('https://onloop.cl/api/productos.php', {
          query: '',
          offset: (page - 1) * 12,
          limit: 12,
        });

        if (response.data.success) {
          // Mezcla aleatoriamente los productos
          const shuffledData = response.data.data.sort(() => 0.5 - Math.random());
          const selectedItems = [];
          const ids = new Set();

          // Selecciona hasta 8 productos únicos para el carrusel
          for (let item of shuffledData) {
            if (selectedItems.length === 8) break;
            if (!ids.has(item.id)) {
              selectedItems.push(item);
              ids.add(item.id);
            }
          }

          setItems(selectedItems);
        } else {
          setError(response.data.message);
          // console.error('Error en la respuesta de la API:', response.data.message);
        }
      } catch (error) {
        setError('Error al obtener los productos: ' + error.message);
        // console.error('Error al obtener los productos:', error);
      } finally {
        setLoading(false); // Finaliza la carga, tanto si hubo éxito como si hubo error
      }
    };

    fetchProductos();
  }, []);

  // Función para obtener la URL de la imagen del producto
  const getImageUrl = (imagen) => imagen;

  // Muestra un indicador de carga mientras se obtienen los productos
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Carousel responsive={responsive}>
        {items.map((item) => {
          // Convierte el título en slug para la URL
          const titleToSlug = (title) => title.toLowerCase().replace(/ /g, '-');
          return (
            <div key={item.id} style={{ padding: '10px' }}>
              <Link
                to={`/producto/${titleToSlug(item.titulo)}/${item.id}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    src={getImageUrl(item.imagen)}
                    alt={item.titulo}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      e.target.src = 'https://onloop.cl/images/Imagen_no_disponible.jpg';
                    }}
                  />
                </div>
                <p className='textoCarruselInicio' style={{ textAlign: 'center' }}>{item.titulo}</p>
                <p className='textoCarruselInicio' style={{ textAlign: 'center' }}>{formatPrice(item.precio)}</p>
              </Link>
            </div>
          );
        })}
      </Carousel>
      {error && <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>}
    </div>
  );
};

export default Carrucel;