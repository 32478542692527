import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box, CircularProgress } from "@mui/material";
import styled from "styled-components";
import { useDarkMode } from "../../Context/DarkModeContext";

const BannerWrapper = styled.div`
  width: 100%;
  min-height: 70vh; /* Mantiene un banner grande en desktop */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Ajustes en modo responsivo (<=768px) */
  @media (max-width: 768px) {
    min-height: auto;    /* Eliminamos la altura mínima en móvil */
    padding: 0;         /* Quitamos padding extra */
    margin: 0;          /* Quitamos margen extra si existiera */
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain; 
`;

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch("https://onloop.cl/api/banners.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ request: "banners" }),
        });
        if (!response.ok) {
          throw new Error(
            `Error en la respuesta: ${response.status} ${response.statusText}`
          );
        }
        const data = await response.json();

        // Filtramos según modo oscuro
        const filteredData = data.filter(
          (banner) => Number(banner.oscuro) === (isDarkMode ? 1 : 0)
        );
        setBanners(filteredData);
      } catch (error) {
        // console.error("Error al obtener los banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, [isDarkMode]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!banners || banners.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <h2>No hay banners disponibles</h2>
      </Box>
    );
  }

  return (
    <Carousel
      key={isDarkMode ? "dark" : "light"}
      autoPlay
      interval={3000}
      animation="fade"
      duration={1000}
      indicators
      indicatorIconButtonProps={{
        style: {
          padding: "10px",
          color: isDarkMode ? "#ccc" : "#888",
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: isDarkMode ? "#fff" : "#000",
        },
      }}
      indicatorContainerProps={{
        style: {
          marginTop: "20px",
          textAlign: "center",
        },
      }}
    >
      {banners.map((banner, index) => (
        <BannerWrapper key={banner.id}>
          <BannerImage src={banner.imagen} alt={`Banner ${index + 1}`} />
        </BannerWrapper>
      ))}
    </Carousel>
  );
};

export default Banners;