import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import axios from "axios";
import banner from "../Image/Banners/contactobanner.jpg";
import { validarRut } from "../Utils/validarRut";
import ReCAPTCHA from "react-google-recaptcha";
import { LocationOnOutlined } from "@mui/icons-material";
import styled from "styled-components";
import "./Styles/SinComponente/Contacto.css";

const LocationButton = ({ id, selected, handleClick, title, address }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(document.body.classList.contains("dark-mode"));
    };
    checkDarkMode();

    // Observador para detectar cambios en el modo oscuro
    const observer = new MutationObserver(checkDarkMode);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div
      onClick={() => handleClick(id)}
      style={{
        cursor: "pointer",
        width: "20rem",
        border: "2px solid #b63232",
        borderRadius: "1rem",
        height: "11rem",
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: selected
          ? "#b63232"
          : isDarkMode
          ? "#121212"
          : "white",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "0.5rem",
        }}
      >
        <LocationOnOutlined
          sx={{
            fontSize: "2.5rem",
            color: selected ? "white" : isDarkMode ? "white" : "#b63232",
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Outfit, sans-serif",
            fontWeight: 700,
            color: selected ? "white" : isDarkMode ? "white" : "#b63232",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Divider
        sx={{
          width: "100%",
          borderWidth: "0.08rem",
          backgroundColor: selected ? "white" : "#b63232",
          mt: 1,
        }}
      />

      <Typography
        variant="body2"
        sx={{
          mt: 1,
          fontFamily: "Outfit, sans-serif",
          fontWeight: 500,
          marginTop: "0.8em",
          fontSize: "100%",
          color: selected ? "white" : "#b63232",
          textAlign: "left",
        }}
      >
        {address}
      </Typography>
    </div>
  );
};

const ContactoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BannerContainer = styled.div`
  .overlay-container-blog {
    position: relative;
    width: 100%;
    height: 250px;
  }

  .foto-blog {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay h1 {
    font-size: 2.5rem;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: bold;
  }
`;

const Contacto = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });
  const [mensajeEnviado, setMensajeEnviado] = React.useState(false);
  const [rutInvalido, setRutInvalido] = React.useState(false);
  const [recaptchaValue, setRecaptchaValue] = React.useState(null);
  const [selectedLocation, setSelectedLocation] = React.useState(0);
  const recaptchaRef = useRef(null);

  const handleClick = (id) => {
    setSelectedLocation(id);
  };

  const formatRut = (rut) => {
    if (!rut) return "";
    rut = rut.replace(/[^0-9kK]/g, "");
    rut = rut.replace(/k/g, "K");
    return rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1)}` : rut;
  };

  const handleRutChange = (event) => {
    const formattedRut = formatRut(event.target.value);
    if (validarRut(formattedRut)) {
      setValue("rut", formattedRut);
      setRutInvalido(false);
    } else {
      setRutInvalido(true);
    }
  };

  const enviar = async (data) => {
    if (!validarRut(getValues("rut"))) {
      setRutInvalido(true);
      return;
    }
    if (data.telefono.length !== 8) return;

    try {
      await axios.post("https://onloop.cl/api/correo.php", {
        ...data,
        recaptchaValue,
      });
      setMensajeEnviado(true);
      reset();
      setRecaptchaValue(null);
      recaptchaRef.current.reset();
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <BannerContainer>
      <div>
        {/* Sección Banner */}
        <div className="overlay-container-blog">
          <img src={banner} className="foto-blog" alt="Banner de Contacto" />
          <div className="overlay">
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Outfit, sans-serif",
                fontWeight: "bold",
                textAlign: "center",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              Contacto
            </Typography>
          </div>
        </div>

        {/* Sección Formulario y Ubicación */}
        <ContactoContainer>
          <Container maxWidth="sm" sx={{ mr: 2, ml: 2 }}>
            <Box sx={{ mt: 0 }}>
              <form onSubmit={handleSubmit(enviar)}>
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Outfit, sans-serif",
                    fontWeight: "bold",
                    mb: 3,
                    fontSize: "1.7rem",
                  }}
                >
                  Envíanos tu consulta
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <TextField
                    label="Ingresa tu nombre"
                    variant="outlined"
                    {...register("nombre", {
                      required: "Este campo es obligatorio",
                    })}
                    fullWidth
                    error={!!errors.nombre}
                    helperText={errors.nombre?.message}
                    InputProps={{ sx: { fontFamily: "Outfit, sans-serif" } }}
                    InputLabelProps={{
                      sx: { fontFamily: "Outfit, sans-serif" },
                    }}
                    className="contactoFields"
                  />
                  <TextField
                    label="Ingresa tu RUT"
                    variant="outlined"
                    {...register("rut", {
                      required: "Este campo es obligatorio",
                    })}
                    fullWidth
                    error={!!errors.rut || rutInvalido}
                    helperText={
                      rutInvalido ? "RUT no válido" : errors.rut?.message
                    }
                    onChange={handleRutChange}
                    InputProps={{ sx: { fontFamily: "Outfit, sans-serif" } }}
                    InputLabelProps={{
                      sx: { fontFamily: "Outfit, sans-serif" },
                    }}
                    className="contactoFields"
                  />
                  <TextField
                    label="Ingresa tu e-mail"
                    variant="outlined"
                    type="email"
                    {...register("email", {
                      required: "Este campo es obligatorio",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "El email debe tener el formato correcto",
                      },
                    })}
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    InputProps={{ sx: { fontFamily: "Outfit, sans-serif" } }}
                    InputLabelProps={{
                      sx: { fontFamily: "Outfit, sans-serif" },
                    }}
                    className="contactoFields"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ mr: 1, fontFamily: "Outfit, sans-serif" }}
                    >
                      +569
                    </Typography>
                    <TextField
                      label="Ingresa tu celular"
                      variant="outlined"
                      className="contactoFields"
                      type="tel"
                      {...register("telefono", {
                        required: "Este campo es obligatorio",
                        minLength: {
                          value: 8,
                          message: "El número debe tener exactamente 8 dígitos",
                        },
                        maxLength: {
                          value: 8,
                          message: "El número debe tener exactamente 8 dígitos",
                        },
                        pattern: {
                          value: /^\d{8}$/,
                          message: "El número debe tener exactamente 8 dígitos",
                        },
                      })}
                      fullWidth
                      error={!!errors.telefono}
                      helperText={errors.telefono?.message}
                      InputProps={{ sx: { fontFamily: "Outfit, sans-serif" } }}
                      InputLabelProps={{
                        sx: { fontFamily: "Outfit, sans-serif" },
                      }}
                    />
                  </Box>
                  <TextField
                    label="Ingresa tu mensaje"
                    variant="outlined"
                    className="contactoFields"
                    {...register("mensaje", {
                      required: "Este campo es obligatorio",
                    })}
                    multiline
                    rows={4}
                    fullWidth
                    error={!!errors.mensaje}
                    helperText={errors.mensaje?.message}
                    InputProps={{ sx: { fontFamily: "Outfit, sans-serif" } }}
                    InputLabelProps={{
                      sx: { fontFamily: "Outfit, sans-serif" },
                    }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf-9FQqAAAAAPpx7_0rhH8lm1tmjAtm8oeyk32g"
                      onChange={handleRecaptchaChange}
                    />
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className="botonEnvioContacto"
                    color="primary"
                    disabled={
                      !isValid ||
                      rutInvalido ||
                      getValues("telefono")?.length !== 8 ||
                      !recaptchaValue
                    }
                    sx={{ fontFamily: "Outfit, sans-serif" }}
                  >
                    Enviar
                  </Button>
                </Box>
              </form>
              {mensajeEnviado && (
                <Box sx={{ mt: 3, textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    color="success.main"
                    sx={{ fontFamily: "Outfit, sans-serif" }}
                  >
                    ¡Tu consulta ha sido enviada exitosamente!
                  </Typography>
                </Box>
              )}
            </Box>
          </Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              padding: "2rem",
            }}
            className={"locationButton"}
          >
            <Typography
              variant="h5"
              color="black"
              sx={{ fontFamily: "Outfit, sans-serif", fontWeight: "Bold", textAlign:"center", mt: 0, mb: 1, fontSize: "140%" }}
            >
              Todos los retiros son con previa <br></br> coordinación
            </Typography>
            <LocationButton
              id={0}
              selected={selectedLocation === 0}
              handleClick={handleClick}
              title="Oficina"
              address="Avenida Pajaritos 3030, Oficina 911, Maipú. Sin atención a público."
            />
            <LocationButton
              id={1}
              selected={selectedLocation === 1}
              handleClick={handleClick}
              title="Bodega"
              address="Avenida Lo Espejo 860, Maipú. Bodega 39."
            />
            <LocationButton
              id={2}
              selected={selectedLocation === 2}
              handleClick={handleClick}
              title="Punto de retiro"
              address="Avenida Central Gonzalo Pérez Llona 1255, Maipú."
            />
          </Box>
        </ContactoContainer>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          {selectedLocation === 0 && (
            <iframe
              title="Oficina"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.071558990731!2d-70.76030478933286!3d-33.499515299914464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c32eb81c9a65%3A0x612b92437f52fa33!2sAv.%20Los%20Pajaritos%203030%2C%209250552%20Maip%C3%BA%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1738328062130!5m2!1ses-419!2scl"
              width="1000"
              height="400"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
          {selectedLocation === 1 && (
            <iframe
              title="Bodega"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.25167565518!2d-70.73214538933163!3d-33.52084160100847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662dd2685e95fc9%3A0x6c39a11007d3ef66!2sAv.%20Lo%20Espejo%20860%2C%209260046%20Maip%C3%BA%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1738328270130!5m2!1ses-419!2scl"
              width="1000"
              height="400"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
          {selectedLocation === 2 && (
            <iframe
              title="Retiro"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.9533556081687!2d-70.74527212504229!3d-33.50259067336884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c3353d0bb097%3A0x7a1580c8940ce13c!2sCentral%20Gonzalo%20P%C3%A9rez%20Llona%201255%2C%209250916%20Maip%C3%BA%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1740426442715!5m2!1ses-419!2scl"
              width="1000"
              height="400"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
        </Box>
      </div>
    </BannerContainer>
  );
};

export default Contacto;
