import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import IsotipoOnLoop from "../Image/Footer/ONLOOP_ISOTIPO.svg";
import TictokLogo from "../Image/Footer/tiktok.svg";
import FacebookLogo from "../Image/Footer/facebook.svg";
import InstagramLogo from "../Image/Footer/instagram.svg";
import MercadoPagoLogo from "../Image/Footer/mercadopago.svg";
import WhatsappLogo from "../Image/Footer/whatsapp.png";
import WebPayLogo from "../Image/Footer/webpay-cl.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate(); // Inicializar navigate

  const handleNavigation = () => {
    navigate("/contacto"); // Redirige a la página de contacto
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleWhatsAppClick = () => {
    const phoneNumber = "+56929587626";
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const url = isMobile
      ? `https://wa.me/${phoneNumber}`
      : `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <Box mt={5} py={1} px={2} bgcolor="#333" color="white" boxShadow={2}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{ mr: 1 }}
      >
        <Grid item xs={12} sm={2} mr={6} ml={0} textAlign="center ">
          {/* Isotipo OnLoop */}
          <Box mb={isSmallScreen ? 2 : 0} mx={isSmallScreen ? 0 : 12}>
            <img src={IsotipoOnLoop} alt="OnLoop Logo" height="100" />
          </Box>
          <Box
            mt={1}
            textAlign={isSmallScreen ? "center" : "left"}
            mx={isSmallScreen ? 0 : 8}
          >
            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              alignItems="center"
            >
              {/* Logo de whatsapp */}
              <img
                src={WhatsappLogo}
                alt="WhatsApp"
                height="35"
                style={{ cursor: "pointer", filter: "invert(1)" }}
                onClick={handleWhatsAppClick}
              />
              <a
                href="https://www.tiktok.com/@onloop.cl"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Logo de TikTok */}
                <img
                  src={TictokLogo}
                  alt="Tictok"
                  height="35"
                  style={{ marginLeft: "8px" }}
                />
              </a>
              <a
                href="https://web.facebook.com/people/Onloop/61565829035656/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Logo de Facebook */}
                <img
                  src={FacebookLogo}
                  alt="Facebook"
                  height="40"
                  style={{ marginLeft: "8px" }}
                />
              </a>
              <a
                href="https://www.instagram.com/onloop.cl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Logo de Instagram */}
                <img
                  src={InstagramLogo}
                  alt="Instagram"
                  height="40"
                  style={{ marginLeft: "8px" }}
                />
              </a>
            </Box>
          </Box>
        </Grid>
        {/* Información de retiro */}
        <Grid item xs={12} sm={4} textAlign="center" sx={{ marginLeft: "0em" }}>
          <Typography
            variant="h6"
            gutterBottom
            onClick={handleNavigation}
            sx={{
              mt: 2,
              fontFamily: "Outfit, sans-serif",
              fontWeight: 700,
              cursor: "pointer",
              color: "inherit",
              display: "inline-flex",
              alignItems: "center",
              gap: "5px",
              borderRadius: "50px", // Mantiene el fondo ovalado
              padding: "0px 16px", // Agrega espacio interno sin afectar el tamaño total
              minHeight: "40px", // Mantiene la altura fija para evitar movimiento
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: "#B63232",
                color: "white",
              },
            }}
          >
            Contacto e información de retiro
            <ArrowOutwardIcon sx={{ fontSize: 18, marginTop: "-2px" }} />
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontFamily: "Outfit, sans-serif",
              fontWeight: 400,
              mt: 1,
            }}
          >
            <strong>Bodega:</strong> Av. Lo Espejo 860, Maipú, RM. Bodega N°39.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Outfit, sans-serif",
              fontWeight: 400,
              mt: 1,
            }}
          >
            <strong>Retiro:</strong> Av. Central Gonzalo Pérez Llona 1255,
            Maipú, RM.
            <br />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Outfit, sans-serif",
              fontWeight: 400,
              mt: 1,
              mb: 1,
            }}
          >
            <strong>Retiro con previa coordinación.</strong>
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} textAlign="center">
          <Typography
            variant="h6"
            component={Link}
            to="/contacto"
            sx={{
              mb: 1,
              marginTop: "-9em",
              fontFamily: "Outfit, sans-serif",
              fontWeight: 700,
              cursor: "pointer",
              color: "inherit",
              display: "inline-flex",
              alignItems: "center",
              gap: "5px",
              borderRadius: "50px",
              padding: "8px 16px",
              maxHeight: "40px",
              textDecoration: "none",
              transition: "background-color 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: "#B63232",
                color: "white",
              },
            }}
          >
            Contáctanos
            <ArrowOutwardIcon sx={{ fontSize: 18, marginTop: "-2px" }} />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Outfit, sans-serif",
              fontWeight: 400,
              marginRight: "0.35em",
            }}
          >
            contacto@onloop.cl
            <br />
            {/* ventas@onloop.cl<br /> */}
            {/* +56987654321<br /> */}
            +569 2958 7626
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} mr={6} textAlign="center">
          <Box>
            <Typography
              variant="h6"
              sx={{
                mt: 5,
                marginBottom: "0em",
                fontFamily: "Outfit, sans-serif",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Medios de Pago
            </Typography>
            <Box
              style={{
                display: "flex",
                marginTop: "-2.3em",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <img
                src={WebPayLogo}
                alt="WebPay"
                style={{ maxWidth: "150px", width: "100%" }}
              />
              <img
                src={MercadoPagoLogo}
                alt="MercadoPago"
                style={{ maxWidth: "150px", width: "100%" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2} textAlign="center"></Grid>
      </Grid>
      <Typography
        marginTop={"-1em"}
        variant="body2"
        textAlign="center"
        sx={{ fontFamily: "Outfit, sans-serif", fontWeight: 700 }}
      >
        © Importadora Scarfran SpA 2024-2025, Todos los derechos reservados.
        {isSmallScreen ? (
          <>
            <br />
            <Link
              to="/terminos"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "Outfit, sans-serif",
                fontWeight: 700,
              }}
            >
              Terminos y condiciones
            </Link>
            <br></br>
            <Link
              to="/politica"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "Outfit, sans-serif",
                fontWeight: 700,
              }}
            >
              Política de cambios y devoluciones
            </Link>
          </>
        ) : (
          <span style={{ marginLeft: "16px" }}>
            <Link
              to="/terminos"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "Outfit, sans-serif",
                fontWeight: 700,
              }}
            >
              Terminos y condiciones
            </Link>
            {" | "}
            <Link
              to="/politica"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "Outfit, sans-serif",
                fontWeight: 700,
              }}
            >
              Política de cambios y devoluciones
            </Link>
          </span>
        )}
      </Typography>
      {!isSmallScreen && (
        <Typography
          variant="body2"
          mb={3}
          style={{
            marginTop: "8px",
            fontFamily: "Outfit, sans-serif",
            fontWeight: 700,
          }}
          textAlign="center"
        >
          Hecho con ❤️ por Onloop
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
