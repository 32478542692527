import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import fotoNosotros from '../Image/Banners/nosotrosbanner.jpg';
import styled from 'styled-components';

const NosotrosContainer = styled.div`

.overlay-container-blog {
    position: relative;
    width: 100%;
    height: 250px; /* Puedes ajustar la altura según sea necesario */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay h1 {
    font-size: 2.5rem; /* Tamaño del texto, ajustable */
    color: white; /* Color del texto */
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
  }
  

.foto-blog {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

`

const Nosotros = () => {
  return (
    <NosotrosContainer>
    <>
      <div className="overlay-container-blog">
        <img src={fotoNosotros} className="foto-blog" alt="" />
        <div className="overlay">
          <h1   style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '50px',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >¡Somos Onloop!</h1>
        </div>
      </div>

      <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>

        <Typography 
          variant="body1" 
          paragraph 
          sx={{  
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Y estamos aquí para traerte lo mejor de las PYMEs de Chile. Aunque somos una nueva tienda online, contamos con más de 15 años de experiencia en el mundo de los emprendedores locales. Lo que nos mueve es ofrecerte productos únicos, hechos con amor y cuidado, siempre con un enfoque cercano y humano.
        </Typography>

        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Nos consideramos los hermanos chicos de una gran familia de emprendedores chilenos, siempre buscando nuevas ideas y queriendo estar cerca de ti. En Onloop encontrarás una amplia variedad de productos, como juguetes saludables libres de sustancias nocivas, juguetes para mascotas no tóxicos, lockers y mobiliario para oficinas, y muebles para el hogar. También ofrecemos productos ecológicos, siempre seleccionados con cariño, para que lo recibas como si te lo entregara un amigo.
        </Typography>

        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Además, somos de Maipú, una comuna llena de vida y esfuerzo. Nos movemos por la 506, la 405 o la línea 5 del metro, igual que tú. Desde aquí, en Maipú, preparamos cada pedido online con dedicación, porque nos importa que te llegue en perfectas condiciones y con la mejor atención.
        </Typography>   

        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Outfit, sans-serif', 
            fontWeight: 400,
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          En Onloop, creemos en los pequeños detalles que marcan la diferencia. Queremos que cada vez que compres en nuestra tienda online de PYMEs, sientas que estás apoyando a emprendedores locales y recibiendo lo mejor que las PYMEs chilenas tienen para ofrecer. Nos enorgullece trabajar con productos sostenibles que mejoran la vida diaria y apoyan a pequeños productores. ¡Gracias por ser parte de nuestra historia!
        </Typography>
      </Box>
    </Container>

    </>
    </NosotrosContainer>
  );
};

export default Nosotros;
