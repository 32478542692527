import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Checkbox,
} from "@mui/material";
// import userIcon from "../../Image/userIcon.png";
// import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import { useNavigate } from "react-router-dom";
import "../Styles/Compra/ConfirmarProductos.css";
import { useDarkMode } from "../../Context/DarkModeContext";

const ConfirmarProductos = ({
  carrito,
  precioTotal,
  handleEliminarProducto,
  setCalcularHabilitado,
  setComunaData,
}) => {
  const { isDarkMode } = useDarkMode(); //  el estado del modo oscuro

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]); // Este useEffect solo se ejecutará cuando `isDarkMode` cambie

  const [comunas, setComunas] = useState([]);
  const [comunasSel, setComunasSel] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedComuna, setSelectedComuna] = useState("");
  const [costoEnvio, setCostoEnvio] = useState(null);
  const [calcularPresionado, setCalcularPresionado] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [enabledField, setEnabledField] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getRegiones = async () => {
      try {
        const response = await fetch("https://onloop.cl/api/regiones.php");
        const data = await response.json();
        if (data.success) {
          setRegiones(data.data);
          // console.log("Regiones obtenidas:", data.data);
        } else {
          // console.error("Error al obtener las regiones:", data.msg);
        }
      } catch (error) {
        // console.error("Error en la solicitud:", error);
      }
    };

    getRegiones();
  }, []);

  useEffect(() => {
    const getComunas = async () => {
      if (selectedRegion.id) {
        try {
          const response = await fetch("https://onloop.cl/api/comunas.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              region_id: selectedRegion.id,
            }),
          });
          const data = await response.json();
          if (data.success) {
            setComunas(data.data);
          } else {
            // console.error("Error al obtener las comunas:", data.msg);
          }
        } catch (error) {
          // console.error("Error en la solicitud:", error);
        }
      }
    };

    getComunas();
  }, [selectedRegion]);

  const handleRegionChange = (event) => {
    const newRegionName = event.target.value;
    const region = regiones.find((region) => region.nombre === newRegionName);
    if (region) {
      setSelectedRegion(region);
      setComunasSel([]);
      setSelectedComuna("");
      setCostoEnvio(null);
      setCalcularPresionado(false);
      setCalcularHabilitado(false);
      setMensajeError("");
      // console.log("Región seleccionada:", region);
    }
  };

  const handleComunaChangeInternal = (event) => {
    const newComuna = event.target.value;
    setSelectedComuna(newComuna);
    setCostoEnvio(null);
    setCalcularPresionado(false);
    setCalcularHabilitado(false);
  };

  useEffect(() => {
    if (selectedRegion.id && comunas.length > 0) {
      const filteredComunas = comunas.filter(
        (comuna) => comuna.region_id === selectedRegion.id
      );
      setComunasSel(filteredComunas);
    }
  }, [selectedRegion, comunas]);

  const isComunaValid = comunasSel.some(
    (comuna) => comuna.nombre === selectedComuna
  );

  const verificarStockYVigencia = async () => {
    try {
      const response = await fetch(
        "https://onloop.cl/api/verificar_stock.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            productos: JSON.stringify(
              carrito.map((producto) => ({
                id_producto: producto.id,
                cantidad: producto.cantidad,
              }))
            ),
          }),
        }
      );

      if (!response.ok) {
        // const errorText = await response.text();
        // console.error(
        //   `Error en la verificación de stock:`,
        //   response.statusText,
        //   errorText
        // );
        throw new Error("Error en la solicitud: " + response.statusText);
      }

      const result = await response.json();
      if (result.success) {
        // console.log('Todos los productos están disponibles y vigentes.');
        return true;
      } else {
        if (result.resultados && Array.isArray(result.resultados)) {
          const errores = result.resultados
            .map(
              (producto) =>
                `El producto "${producto.titulo}" ${producto.razon}.`
            )
            .join("\n");
          setMensajeError(errores);
        }
        return false;
      }
    } catch (error) {
      // console.error("Error al verificar el stock:", error.message);
      // setMensajeError("Error de conexión al verificar el stock");
      return false;
    }
  };

  const handleCalcular = async () => {
    const stockValido = await verificarStockYVigencia();

    if (!stockValido) {
      return;
    }

    // console.log("ID de la región seleccionada:", selectedRegion.id);
    // console.log("Precio total:", precioTotal());

    const categoriasPermitidas = ["Juguetes", "Mascotas"];
    const todasCategoriasValidas = carrito.every((producto) =>
      categoriasPermitidas.includes(producto.categoria.toLowerCase())
    );

    const totalPrecio = parseFloat(precioTotal());

    let nuevoCostoEnvio;
    if (
      Number(selectedRegion.id) === 7 &&
      totalPrecio > 25990 &&
      todasCategoriasValidas
    ) {
      nuevoCostoEnvio = 0;
    } else if (
      Number(selectedRegion.id) === 7 &&
      totalPrecio < 25990 &&
      todasCategoriasValidas
    ) {
      const comunaSeleccionada = comunasSel.find(
        (comuna) => comuna.nombre === selectedComuna
      );
      if (comunaSeleccionada) {
        nuevoCostoEnvio = comunaSeleccionada.primer_precio;
      }
    } else if (Number(selectedRegion.id) !== 7) {
      nuevoCostoEnvio = 0;
    } else {
      const comunaSeleccionada = comunasSel.find(
        (comuna) => comuna.nombre === selectedComuna
      );
      if (comunaSeleccionada) {
        nuevoCostoEnvio =
          totalPrecio > 55000
            ? comunaSeleccionada.segundo_precio
            : comunaSeleccionada.primer_precio;
        nuevoCostoEnvio = nuevoCostoEnvio === 0 ? 0 : nuevoCostoEnvio;
      }
    }

    const costoEnvioFinal = nuevoCostoEnvio || 0;

    setCostoEnvio(costoEnvioFinal);
    setCalcularPresionado(true);
    setCalcularHabilitado(true);

    setComunaData({
      selectedRegion,
      selectedComuna,
      costoEnvio: costoEnvioFinal,
      enabledField,
    });
  };

  const handleEliminarProductoInternal = (productoId) => {
    handleEliminarProducto(productoId);
    setSelectedComuna("");
    setSelectedRegion("");
    setMensajeError("");
    setCalcularHabilitado(false);
  };

  const formatoCLP = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });

  const handleCheckboxChange = (field) => {
    if (enabledField === field) {
      setEnabledField("");
      setSelectedRegion({});
      setSelectedComuna("");
      setComunasSel([]);
      setCalcularPresionado(false);
      setCalcularHabilitado(false);
    } else {
      setEnabledField(field);
      setSelectedRegion({});
      setSelectedComuna("");
      setComunasSel([]);
      setCalcularPresionado(false);
      setCalcularHabilitado(false);
    }
  };

  return (
    <Box sx={{ padding: { xs: 2, sm: 3 }, maxWidth: "800px", margin: "auto" }}>
      <Grid container spacing={5} alignItems="center">
        {/* <Grid item xs={12} alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            sx={{
              margin: "auto",
              marginTop: "-2em",
              backgroundColor: "#CCCCCC",
              borderRadius: "25px", // Borde ovalado
              padding: "10px 20px", // Agregar padding
              marginBottom: "1em",
              maxWidth: "60%",
            }}
          >
            <img src={userIcon} alt="User Icon" style={{ width: "5%" }} />
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: "Outfit, sans-serif",
                fontWeight: 400,
                marginTop: "1em",
                marginLeft: "1.1em",
                fontSize: "110%",
                textAlign: "center",
                textJustify: "inter-word",
                "&:last-of-type": {
                  textAlign: "center",
                },
              }}
            >
              Esto es más rápido <br></br>
              <Button
                variant="outlined"
                onClick={() => navigate("/iniciar-sesion")}
                sx={{
                  backgroundColor: "#B63232",
                  color: "white",
                  borderColor: "#B63232",
                  padding: "0.2em 0.4em",
                  marginBottom: "0.3em",
                  marginLeft: "0.1em",
                  marginTop: "1em",
                  marginBottom: "1em",
                  marginRight: "0.1em",
                  fontSize: "90%",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#e03e3e",
                    borderColor: "#e03e3e",
                  },
                }}
              >
                Iniciando sesión
              </Button>{" "}
              o{" "}
              <Button
                variant="outlined"
                onClick={() => navigate("/register")}
                sx={{
                  backgroundColor: "#B63232",
                  color: "white",
                  borderColor: "#B63232",
                  padding: "0.2em 0.4em",
                  marginBottom: "0.3em",
                  fontSize: "90%",
                  marginTop: "1em",
                  marginBottom: "1em",
                  marginLeft: "0.1em",
                  marginRight: "0.1em",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#e03e3e",
                    borderColor: "#e03e3e",
                  },
                }}
              >
                Registrándote
              </Button>{" "}
              <br></br>
              ¡Así usas tus datos y direcciones guardadas!
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: "#CCCCCC",
              borderRadius: "25px", // Borde ovalado
              padding: "10px 20px", // Agregar padding
            }}
          >
            <EmojiObjectsIcon sx={{ marginRight: 3 }} />
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontFamily: "Outfit, sans-serif",
                fontWeight: 400,
                marginTop: "1em",
                fontSize: "110%",
                textAlign: "justify",
                textJustify: "inter-word",
                "&:last-of-type": {
                  textAlign: "left",
                },
              }}
            >
              Para agregar productos a tu canasta, presiona el botón 'Seguir
              comprando' ubicado al inicio de la página. Serás enviado a la
              tienda nuevamente.
            </Typography>
          </Box>
        </Grid> */}

        {carrito.length > 0 ? (
          <>
            {carrito.map((producto) => {
              let costeAdicionalModificado = 0;

              if (Number(selectedRegion.id) === 7) {
                costeAdicionalModificado = producto.coste_adicional;

                if (costeAdicionalModificado !== 0) {
                  if (
                    producto.cantidad < 2 &&
                    costeAdicionalModificado === 2000
                  ) {
                    costeAdicionalModificado = 0;
                  } else if (producto.cantidad >= 2) {
                    costeAdicionalModificado = (producto.cantidad - 1) * 2000;
                  }
                }
              }

              return (
                <Grid item xs={12} key={producto.id}>
                  <Box sx={{ marginBottom: 3 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "4px",
                      }}
                    >
                      {producto.titulo}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "4px",
                        "&:last-of-type": {
                          textAlign: "left",
                        },
                      }}
                    >
                      Precio unitario: {formatoCLP.format(producto.precio)}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "4px",
                        "&:last-of-type": {
                          textAlign: "left",
                        },
                      }}
                    >
                      Precio total:{" "}
                      {formatoCLP.format(producto.precio * producto.cantidad)}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "4px",
                        "&:last-of-type": {
                          textAlign: "left",
                        },
                      }}
                    >
                      Cantidad: {producto.cantidad}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "4px",
                        "&:last-of-type": {
                          textAlign: "left",
                        },
                      }}
                    >
                      Categoría: {producto.categoria}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                        marginBottom: "4px",
                        "&:last-of-type": {
                          textAlign: "left",
                        },
                      }}
                    >
                      Costo adicional de envío:{" "}
                      {formatoCLP.format(costeAdicionalModificado)}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        handleEliminarProductoInternal(producto.id)
                      }
                      sx={{
                        backgroundColor: "#ff4d4d",
                        "&:hover": { backgroundColor: "#e03e3e" },
                        marginTop: 1,
                        fontFamily: "Outfit, sans-serif",
                        fontWeight: 400,
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      Quitar unidad
                    </Button>
                    <Divider sx={{ marginTop: 2 }} />
                  </Box>
                </Grid>
              );
            })}

            <Divider sx={{ marginTop: 2 }} />
            <Grid item xs={12}>
              <Paper
                elevation={enabledField === "Retiro" ? 4 : 1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 2,
                  backgroundColor:
                    enabledField === "Retiro" ? "#f0f4ff" : "#f9f9f9",
                }}
                className={"checkboxRetiro"}
              >
                <Checkbox
                  checked={enabledField === "Retiro"}
                  onChange={() => handleCheckboxChange("Retiro")}
                />
                <Typography
                  sx={{
                    color:
                      enabledField === "Retiro"
                        ? "text.primary"
                        : "text.disabled",
                    fontFamily: "Outfit, sans-serif",
                    fontWeight: 400,
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                  className={"retiroEnBodega"}
                >
                  Retiro en bodega o punto de retiro (Gratis, previa
                  coordinación)
                </Typography>
              </Paper>

              <Paper
                elevation={enabledField === "Entrega" ? 4 : 1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  p: 2,
                  backgroundColor:
                    enabledField === "Entrega" ? "#f0f4ff" : "#f9f9f9",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  className={"seccionEntrega"}
                >
                  <Checkbox
                    className={"checkboxEntrega"}
                    checked={enabledField === "Entrega"}
                    onChange={() => handleCheckboxChange("Entrega")}
                    sx={{
                      color: isDarkMode ? "#000" : "inherit",
                      "&.Mui-checked": {
                        color: isDarkMode ? "#000" : "inherit",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 1,
                      flexGrow: 1,
                    }}
                  >
                    <FormControl
                      fullWidth
                      sx={{ mb: 2 }}
                      disabled={enabledField !== "Entrega"}
                      className={"formRegion"}
                    >
                      <InputLabel
                        id="region-label"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          fontWeight: 400,
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        Selecciona tu Región
                      </InputLabel>
                      <Select
                        labelId="region-label"
                        label="Selecciona tu Región"
                        className="region-select"
                        value={selectedRegion.nombre || ""}
                        onChange={handleRegionChange}
                        required
                        variant="outlined"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          fontWeight: 400,
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        <MenuItem
                          value=""
                          disabled
                          sx={{
                            fontFamily: "Outfit, sans-serif",
                            fontWeight: 400,
                            textAlign: "justify",
                            textJustify: "inter-word",
                          }}
                        >
                          Seleccione región
                        </MenuItem>
                        {regiones.map((region) => (
                          <MenuItem
                            key={region.id}
                            value={region.nombre}
                            sx={{
                              fontFamily: "Outfit, sans-serif",
                              fontWeight: 400,
                              textAlign: "justify",
                              textJustify: "inter-word",
                            }}
                          >
                            {region.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      sx={{ mb: 2 }}
                      disabled={
                        !selectedRegion.id || enabledField !== "Entrega"
                      }
                      className={"formComuna"}
                    >
                      <InputLabel
                        id="comuna-label"
                        className="comuna-select"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          fontWeight: 400,
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        Selecciona tu Comuna
                      </InputLabel>
                      <Select
                        labelId="comuna-label"
                        label="Selecciona tu Comuna"
                        value={isComunaValid ? selectedComuna : ""}
                        onChange={handleComunaChangeInternal}
                        required
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          fontWeight: 400,
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        <MenuItem
                          value=""
                          disabled
                          sx={{
                            fontFamily: "Outfit, sans-serif",
                            fontWeight: 400,
                            textAlign: "justify",
                            textJustify: "inter-word",
                          }}
                        >
                          Seleccione comuna
                        </MenuItem>
                        {comunasSel.map((comuna) => (
                          <MenuItem
                            key={comuna.id}
                            value={comuna.nombre}
                            sx={{
                              fontFamily: "Outfit, sans-serif",
                              fontWeight: 400,
                              textAlign: "justify",
                              textJustify: "inter-word",
                            }}
                          >
                            {comuna.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Paper>

              <Button
                sx={{
                  backgroundColor: "#ff4d4d",
                  "&:hover": { backgroundColor: "#e03e3e" },
                  mt: 2,
                  fontFamily: "Outfit, sans-serif",
                  fontWeight: 400,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
                variant="contained"
                onClick={handleCalcular}
                disabled={enabledField !== "Retiro" && !isComunaValid}
              >
                Confirmar
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  marginTop: 2,
                  fontFamily: "Outfit, sans-serif",
                  fontWeight: 400,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Sub-total: {formatoCLP.format(parseInt(precioTotal(), 10))}
              </Typography>
              {calcularPresionado && (
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    fontFamily: "Outfit, sans-serif",
                    fontWeight: 400,
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  Costo de envío:{" "}
                  {enabledField === "Retiro"
                    ? formatoCLP.format(0)
                    : Number(selectedRegion.id) !== 7 && costoEnvio === 0
                    ? "Se debe negociar"
                    : formatoCLP.format(costoEnvio)}
                </Typography>
              )}
            </Grid>
            {mensajeError && (
              <Grid item xs={12}>
                <Typography color="error">{mensajeError}</Typography>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h5">
              Agrega productos al carrito para continuar
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ConfirmarProductos;
