import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useDarkMode } from "../../Context/DarkModeContext"; 

const toCapital = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatPrice = (price) => {
  const numericPrice = Number(price);
  return isNaN(numericPrice)
    ? "Precio no disponible"
    : numericPrice.toLocaleString("es-CL", {
        style: "currency",
        currency: "CLP",
      });
};

const Item = ({ producto }) => {
  const { isDarkMode } = useDarkMode(); //  el estado del modo oscuro

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]); // Este useEffect solo se ejecutará cuando `isDarkMode` cambie

  const [imageSrc, setImageSrc] = useState(() => `${producto.imagen}`);

  const handleImageError = () => {
    setImageSrc("https://onloop.cl/images/Imagen_no_disponible.jpg");
  };

  const titleToSlug = (title) => {
    return title.toLowerCase().replace(/ /g, "-");
  };

  return (
    <Card
      component={Link}
      to={`/producto/${titleToSlug(producto.titulo)}/${producto.id}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: isDarkMode? "black" : "white",
        gap: "1rem",
        height: "100%",
        padding: "1rem",
        textDecoration: "none",
        color: "inherit",
        "@media (max-width: 599px)": {
          padding: "0.5rem",
        },
        "&:hover .precio": {
          backgroundColor: "red !important",
          color: "white !important",
        },
      }}
    >
      <CardMedia
        component="img"
        image={imageSrc}
        alt={`Image of ${producto.titulo}`}
        onError={handleImageError}
        sx={{
          height: "180px",
          objectFit: "contain",
          "@media (max-width: 599px)": {
            height: "120px",
          },
        }}
      />
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "1rem 0",
          "@media (max-width: 599px)": {
            padding: "0.5rem 0",
          },
        }}
        className={"itemContent"}
      >
        <div>
          <Typography
            variant="h6"
            component="h4"
            className={"itemTitle"}
            sx={{
              fontWeight: 400,
              textAlign: "justify",
              textJustify: "inter-word",
              fontSize: "1.25rem",
              fontFamily: "Outfit, sans-serif",
              "@media (max-width: 599px)": {
                fontSize: "1rem",
              },
            }}
          >
            {producto.titulo}
          </Typography>

          {/* SKU eliminado a petición del cliente */}

          {/* <Typography
            sx={{
              fontSize: "1rem",
              fontFamily: "Outfit, sans-serif",
              "@media (max-width: 599px)": {
                fontSize: "0.875rem",
              },
            }}
          >
            {`SKU: ${producto.modelo}`}
          </Typography> */}

          <Typography
            sx={{
              fontSize: "1rem",
              marginTop: "0.5rem",
              fontFamily: "Outfit, sans-serif",
              "@media (max-width: 599px)": {
                fontSize: "0.75rem",
              },
            }}
          >
            Categoría: {toCapital(producto.categoria)}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              fontFamily: "Outfit, sans-serif",
              "@media (max-width: 599px)": {
                fontSize: "0.875rem",
              },
              fontWeight: "bold",
              borderRadius: "20px",
              display: "inline-block",
              padding: "0.2rem 0.5rem",
              marginLeft: "-0.5em",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            className={"precio"}
          >
            Precio: {formatPrice(producto.precio)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default Item;
