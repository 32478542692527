import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   // Recupera el usuario del localStorage, si existe
//   const [user, setUser] = useState(() => {
//     const storedUser = localStorage.getItem('user');
//     if (storedUser) {
//       console.log("Usuario recuperado del localStorage:", storedUser);
//       return JSON.parse(storedUser);
//     } else {
//       console.log("No se encontró usuario en el localStorage.");
//       return null;
//     }
//   });

//    // Actualiza el localStorage cada vez que cambia el usuario
//    useEffect(() => {
//     if (user) {
//       console.log("Guardando usuario en localStorage:", user);
//       localStorage.setItem('user', JSON.stringify(user));
//     } else {
//       console.log("El usuario ha sido eliminado. Eliminando del localStorage.");
//       localStorage.removeItem('user');
//     }
//   }, [user]);

//   // Función para cerrar sesión
//   const logout = () => {
//     setUser(null);
//   };

//   return (
//     <UserContext.Provider value={{ user, setUser, logout }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

export default UserContext;