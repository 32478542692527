import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import Banner from "./Banner";
import Carrucel from "./Carrucel";
import Categorias from "./Categorias";
import Beneficios from "./Beneficios";
import "../Styles/Inicio/Inicio.css";
import bannerLoading from "../../Image/Banners/bannerLoading.jpg";
import { useDarkMode } from "../../Context/DarkModeContext"; 

function Inicio() {
  const { isDarkMode } = useDarkMode();  //  el estado del modo oscuro

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);  // Este useEffect solo se ejecutará cuando `isDarkMode` cambie  

  useEffect(() => {
    axios
      .get("https://onloop.cl/api/database.php")
      .then((response) => {
        // console.log('Datos de la base de datos:', response.data);
      })
      .catch((error) => {
        // console.error('Hubo un error al obtener los datos:', error);
      });
  }, []);

  return (
    <Grid container className="inicio-container">
      <Grid item xs={12}>
        <Banner />
      </Grid>
      <Grid item xs={12}>
        <div className="productos-section productos-title">
          <Typography className="inicio-title">
            <h2></h2>
            <h3>
              Aquí no necesitas ofertas: Descubre los productos que no te puedes
              perder. Nuevos, con 6 meses de garantía y hechos para ti ¡Pero a
              precios de outlet!. Esta lista cambia periódicamente: Podrás
              encontrar sillas y escritorios perfectos para tu homeoffice,
              soluciones de almacenaje, casilleros, cajoneras y juguetes
              perfectos para tus favoritos (¡niños, perritos o gatitos, tenemos
              de todo!).
            </h3>
          </Typography>
          <Carrucel />

          <div className="imagen-contenedor">
            <img
              src={bannerLoading}
              alt="Imagen de inicio"
              className="inicio-imagen"
            />
            <h2 className="texto-encima">
              Sabemos de precios bajos. Conoce nuestra selección de productos
              destacados.
            </h2>
          </div>

          <Typography className="inicio-title">
            <h2>Categorías</h2>
            <h3>
              Somos especialistas en todo lo que vendemos, pero especialmente en
              estas líneas: Conoce nuestros lockers y casilleros de acero,
              nuestros muebles de oficina (¡Ideales para hacer homeoffice!) y
              nuestra línea de mascotas, libres de BPA, sin materiales nocivos y
              amigables con tu compañero. ¡Conoce todos los productos que
              tenemos para ti!
            </h3>
          </Typography>

          <Categorias />
          <div
            className={"porQueElegirnos"}
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <Typography className="inicio-title">
              <h2 style={{ margin: "10px 0px 20px 0px" }}>
                ¿Por qué elegirnos?
              </h2>
            </Typography>
            <Beneficios />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Inicio;
