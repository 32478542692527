import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import '../Styles/Inicio/Inicio.css';

// Importa las imágenes desde la carpeta 'assets'
import mueblesImg  from   '../../Image/categoria1.png';
import lockerImg from  '../../Image/categoria2.png';
import mascotaImg from  '../../Image/categoria3.png';

export default function Categorias() {
  const itemData = [
    {
      id: 1,
      img: lockerImg,
      title: 'LOCKERS',
      description: 'Casilleros metálicos y plásticos con certificación ISO. Económicos, confiables y de fácil armado.',
      link: '/productos?categories=Lockers',
    },
    {
      id: 2,
      img: mueblesImg,
      title: 'MUEBLES DE OFICINA',
      description: 'Arma tu oficina desde cero con nuestras sillas ergonómicas, escritorios metálicos y regulables en altura.',
      link: '/productos?categories=Papelería%2CSilleria%2CEscritorios',
    },
    {
      id: 3,
      img: mascotaImg,
      title: 'MASCOTA',
      description: 'Quiérelos más que nadie en el mundo con nuestros productos libres de materiales nocivos. ¡Acessorios, bolsas biodegradables, camitas suaves y más!',
      link: '/productos?categories=Mascotas',
    },
    {
      id: 4,
      title: 'Y mucho más!',
      description: 'AQUÍ',
      link: '/productos',
    },
  ];

  return (
    <div className='section-categorias'>
      <Grid container spacing={2} justifyContent="center">
        {/* Primera fila con 6 col para cada imagen */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card elevation={3} style={{ margin: '5px', position: 'relative' }}>
              <CardMedia
                component="img"
                height="200"
                image={itemData[0].img}
                alt={itemData[0].title}
                style={{ objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  zIndex: 1,
                  padding: '20px',
                  textAlign: 'center',
                  paddingTop: '20px',
                }}
              >
                <Link className='boton-categoria' to={itemData[0].link}>
                  <Button size="small">Ver más</Button>
                </Link>
                <Typography
                  variant="h3"
                  component="div"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    textAlign: 'end',
                    fontFamily: 'Outfit, sans-serif',
                  }}
                >
                  {itemData[0].title}
                </Typography>
                <Typography
                  variant="p"
                  style={{
                    fontSize: '16px',
                    fontFamily: 'inter',
                    textAlign: 'left',
                  }}
                  className={'descripcionCategoria'}
                >
                  {itemData[0].description}
                </Typography>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card elevation={3} style={{ margin: '5px', position: 'relative' }}>
              <CardMedia
                component="img"
                height="200"
                image={itemData[1].img}
                alt={itemData[1].title}
                style={{ objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  zIndex: 1,
                  padding: '20px',
                  textAlign: 'center',
                  paddingTop: '20px',
                }}
              >
                <Link className='boton-categoria' to={itemData[1].link}>
                  <Button size="small">Ver más</Button>
                </Link>
                <Typography
                  variant="h3"
                  component="div"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    textAlign: 'end',
                    fontFamily: 'Outfit, sans-serif',
                  }}
                >
                  {itemData[1].title}
                </Typography>
                <Typography
                  variant="p"
                  style={{
                    fontSize: '16px',
                    fontFamily: 'inter',
                    textAlign: 'left',
                  }}
                  className={'descripcionCategoria'}
                >
                  {itemData[1].description}
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>

        {/* Segunda fila: 8 col a la izquierda y 4 col a la derecha */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={8}>
            <Card elevation={3} style={{ margin: '5px', position: 'relative' }}>
              <CardMedia
                component="img"
                height="200"
                image={itemData[2].img}
                alt={itemData[2].title}
                style={{ objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  zIndex: 1,
                  padding: '20px',
                  textAlign: 'center',
                  paddingTop: '20px',
                }}
              >
                <Link className='boton-categoria' to={itemData[2].link}>
                  <Button size="small">Ver más</Button>
                </Link>
                <Typography
                  variant="h3"
                  component="div"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    textAlign: 'end',
                    fontFamily: 'Outfit, sans-serif',
                  }}
                >
                  {itemData[2].title}
                </Typography>
                <Typography
                  variant="p"
                  style={{
                    fontSize: '16px',
                    fontFamily: 'inter',
                    textAlign: 'left',
                  }}
                  className={'descripcionCategoria'}
                >
                  {itemData[2].description}
                </Typography>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card elevation={3} style={{ margin: '5px', position: 'relative' }}>
              <CardMedia
                component="img"
                height="200"
                image={itemData[3].img}
                alt={itemData[3].title}
                style={{ objectFit: 'cover' }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#b63232',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1,
                  padding: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  component="div"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    fontFamily: 'Outfit, sans-serif',
                    marginBottom: '20px',
                  }}
                >
                  {itemData[3].title}
                </Typography>
                <Typography
                  variant="p"
                  style={{
                    fontSize: '48px',
                    fontFamily: 'inter, serif',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                  }}
                  className={'descripcionCategoria'}
                >
                  {itemData[3].description}
                </Typography>
                <Link className='boton-categoria-mas' to={itemData[3].link}>
                  <Button size="small" style={{ marginTop: '20px' }}>ir a productos</Button>
                </Link>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
