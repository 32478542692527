import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  Link,
} from "@mui/material";
import UserContext from "../../../Context/UserContext";
import fotoBeneficios from "../../../Image/foto-beneficio.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import { validarRut } from "../../../Utils/validarRut";
import "../..//Styles/Login/Register.css";
import { useDarkMode } from "../../../Context/DarkModeContext";

/**
 * Componente de registro de usuario.
 * Permite al usuario registrarse ingresando sus datos personales y,
 * opcionalmente, los datos de su empresa. Incorpora validaciones de campos,
 * uso de ReCAPTCHA y soporte para modo oscuro.
 */
const Register = () => {
  // Se obtiene el estado del modo oscuro desde el contexto
  const { isDarkMode } = useDarkMode();

  // Efecto para aplicar la clase "dark-mode" al body si el modo oscuro está activo
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  // Estados para la información personal del usuario
  const [nombre, setNombre] = useState("");
  const [rut, setRut] = useState("");
  const [rutEmpresa, setRutEmpresa] = useState("");
  const [usuario, setUsuario] = useState("");
  const [clave, setClave] = useState("");
  const [telefono, setTelefono] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [correo, setCorreo] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [tieneEmpresa, setTieneEmpresa] = useState(false);
  const [razonSocial, setRazonSocial] = useState("");
  const [giro, setGiro] = useState("");
  const [direccionEmpresa, setDireccionEmpresa] = useState("");

  // Referencia para el componente ReCAPTCHA
  const recaptchaRef = useRef(null);

  // Estado para confirmar la aceptación de términos y condiciones
  const [leidoTerminos, setLeidoTerminos] = useState(false);

  // Estado para mensajes de validación o error en el formulario
  const [message, setMessage] = useState("");

  // Contexto para el usuario (estado global)
  const { setUser } = useContext(UserContext);

  // Estados de validación para campos específicos
  const [rutInvalido, setRutInvalido] = useState(false);
  const [claveInvalida, setClaveInvalida] = useState(false);
  const [correoInvalido, setCorreoInvalido] = useState(false);
  const [fechaInvalida, ] = useState(false);
  const [telefonoInvalido, setTelefonoInvalido] = useState(false);

  // Funciones de validación

  /**
   * Actualiza el estado del RUT.
   * @param {object} event Evento del input.
   */
  const handleRutChange = (event) => {
    setRut(event.target.value);
  };

  /**
   * Callback para manejar la validez del RUT.
   * Actualmente se utiliza para imprimir en consola.
   * @param {boolean} isValid Indica si el RUT es válido.
   */
  const handleRutValidoChange = (isValid) => {
    console.log("RUT válido:", isValid);
  };

  /**
   * Formatea el RUT eliminando caracteres no válidos y agregando el guion antes del dígito verificador.
   * @param {string} rut RUT sin formatear.
   * @returns {string} RUT formateado.
   */
  const formatRut = (rut) => {
    if (!rut) return "";
    rut = rut.replace(/[^0-9kK]/g, "");
    rut = rut.replace(/k/g, "K");
    return rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1)}` : rut;
  };

  /**
   * Maneja el cambio en el campo de RUT formateándolo y validándolo.
   * @param {object} event Evento del input.
   */
  const handleFormattedRutChange = (event) => {
    const formattedRut = formatRut(event.target.value);

    // Actualiza el RUT con el valor formateado
    handleRutChange({
      ...event,
      target: { ...event.target, value: formattedRut },
    });

    // Valida el RUT y actualiza el estado correspondiente
    if (validarRut(formattedRut)) {
      setRutInvalido(false);
      handleRutValidoChange(true);
    } else {
      setRutInvalido(true);
      handleRutValidoChange(false);
    }
  };

  /**
   * Actualiza el estado del teléfono.
   * @param {object} event Evento del input.
   */
  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
  };

  /**
   * Maneja el cambio en el campo de teléfono, permitiendo solo hasta 8 dígitos.
   * @param {object} event Evento del input.
   */
  const handleFormattedTelefonoChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,8}$/.test(value)) {
      handleTelefonoChange(event);
      // Si el número tiene 8 dígitos se considera válido
      if (value.length === 8) {
        setTelefonoInvalido(false);
      } else {
        setTelefonoInvalido(true);
      }
    }
  };

  /**
   * Maneja el cambio en la contraseña y valida que cumpla con los requisitos:
   * - Entre 8 y 16 caracteres.
   * - Al menos una mayúscula, una minúscula y un número.
   * @param {object} event Evento del input.
   */
  const handleFormattedClaveChange = (event) => {
    setClave(event.target.value);

    const claveRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,16}$/;

    if (!claveRegex.test(event.target.value)) {
      setClaveInvalida(true);
    } else {
      setClaveInvalida(false);
    }
  };

  /**
   * Maneja el cambio en el correo y valida su formato.
   * @param {object} event Evento del input.
   */
  const handleFormattedCorreoChange = (event) => {
    setCorreo(event.target.value);
    const correoRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!correoRegex.test(event.target.value)) {
      setCorreoInvalido(true);
    } else {
      setCorreoInvalido(false);
    }
  };

  /**
   * Actualiza el estado de la fecha de nacimiento.
   * @param {object} event Evento del input.
   */
  const handleFormattedFechaChange = (event) => {
    setFechaNacimiento(event.target.value);
  };

  /**
   * Maneja el cambio del checkbox que indica si el usuario tiene una empresa.
   * @param {object} event Evento del checkbox.
   */
  const handleCheckboxChange = (event) => {
    setTieneEmpresa(event.target.checked);
  };

  // Objeto que reúne todos los datos del usuario a enviar al backend.
  const userData = {
    nombre,
    rut,
    usuario,
    clave,
    telefono,
    correo,
    fechaNacimiento,
    recaptchaValue,
    /* Se incluyen los datos de la empresa si el usuario los ha proporcionado */
    ...(tieneEmpresa && {
      empresa: {
        rutEmpresa,
        razonSocial,
        giro,
        direccionEmpresa,
      },
    }),
  };

  /**
   * Maneja el proceso de registro del usuario.
   * Realiza las validaciones necesarias y envía los datos al backend mediante axios.
   */
  const handleRegister = async () => {
    // Validación de campos requeridos
    if (
      !nombre ||
      !rut ||
      !usuario ||
      !clave ||
      !telefono ||
      !correo ||
      !fechaNacimiento ||
      !leidoTerminos
    ) {
      setMessage(
        "Por favor completa todos los campos requeridos y acepta los términos."
      );
      return;
    }

    // Validación de ReCAPTCHA
    if (!recaptchaValue) {
      setMessage("Por favor, verifica que no eres un robot.");
      return;
    }

    // Validación del RUT
    if (rutInvalido) {
      setMessage("El RUT ingresado no es válido.");
      return;
    }

    // Validación de la contraseña
    if (claveInvalida) {
      setMessage(
        "La clave debe tener al menos una mayúscula, una minúscula, un número y entre 8 y 16 caracteres."
      );
      return;
    }

    // Validación del correo
    if (correoInvalido) {
      setMessage("El correo no es válido.");
      return;
    }

    // Validación de la fecha (por ejemplo, si debe ser anterior a la fecha actual)
    if (fechaInvalida) {
      setMessage(
        "La fecha de nacimiento debe ser anterior a la fecha actual y mínimo 16 años antes."
      );
      return;
    }

    // Muestra los datos en consola para verificación antes de enviarlos
    console.log("Datos enviados al backend:", userData);

    try {
      // Envío de datos al backend mediante una solicitud POST
      const response = await axios.post(
        "https://pruebas.onloop.cl/api/registro.php",
        userData
      );

      // Verifica la respuesta del servidor
      if (response.data.success) {
        setMessage("Registro exitoso!");
        setUser(userData);
      } else {
        setMessage(response.data.message || "Hubo un error al registrar.");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);

      // Manejo detallado de errores según la respuesta del servidor
      if (error.response) {
        console.error("Detalles del error:", error.response);
        setMessage(
          `Error del servidor: ${
            error.response.data.message || "Ocurrió un problema"
          }`
        );
      } else if (error.request) {
        setMessage("No se recibió respuesta del servidor.");
      } else {
        setMessage("Error en la comunicación con el servidor.");
      }
    }
  };

  // Validación global del formulario para determinar si se habilita el botón de registro.
  const isFormValid =
    nombre &&
    rut &&
    usuario &&
    clave &&
    telefono &&
    correo &&
    fechaNacimiento &&
    leidoTerminos &&
    correo.trim() !== "" &&
    clave.trim() !== "" &&
    recaptchaValue !== null &&
    !claveInvalida &&
    !correoInvalido &&
    !fechaInvalida;

  /**
   * Maneja el cambio en el valor del ReCAPTCHA.
   * @param {string} value Valor retornado por el componente ReCAPTCHA.
   */
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <Container maxWidth="sm">
      {/* Título y subtítulo del registro */}
      <Box sx={{ mt: 8, textAlign: "center" }}>
        <Typography variant="h4">¡Regístrate en Onloop!</Typography>
      </Box>
      <Box sx={{ mt: 4, textAlign: "center", fontSize: "140%" }}>
        <Typography variant="h7">
          Ingresa tus datos para guardar tus compras con RUT y Boleta
        </Typography>
      </Box>
      <Box sx={{ mt: 6 }}>
        <Grid container spacing={2} id="registerContainer">
          {/* Información Personal */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="nombre"
              label="Nombre Completo"
              variant="outlined"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              placeholder="Ej: Juan Pérez"
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="rut"
              label="RUT"
              variant="outlined"
              value={rut}
              onChange={handleFormattedRutChange}
              error={rutInvalido}
              helperText={rutInvalido ? "RUT no válido" : ""}
              placeholder="Ej: 12.345.678-9"
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="usuario"
              label="Usuario"
              variant="outlined"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="clave"
              label="Contraseña"
              type="password"
              variant="outlined"
              value={clave}
              onChange={handleFormattedClaveChange}
              error={claveInvalida}
              helperText={
                claveInvalida
                  ? "La clave debe tener de 8 a 16 caracteres, con una mayúscula, una minúscula y un número"
                  : ""
              }
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  mr: 1,
                  fontFamily: "Outfit, sans-serif",
                  fontWeight: 400,
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                +569
              </Typography>
              <TextField
                fullWidth
                id="telefono"
                label="Teléfono"
                variant="outlined"
                value={telefono}
                onChange={handleFormattedTelefonoChange}
                error={telefonoInvalido}
                helperText={
                  telefonoInvalido ? "El teléfono debe tener 8 dígitos" : ""
                }
                placeholder="12345678"
                required
                sx={{ mb: 0 }}
                InputLabelProps={{
                  sx: {
                    color: "black",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="correo"
              label="Correo"
              variant="outlined"
              value={correo}
              onChange={handleFormattedCorreoChange}
              error={correoInvalido}
              helperText={
                correoInvalido ? "El correo no tiene el formato válido" : ""
              }
              placeholder="Ej: ejemplo@correo.com"
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
            />
          </Grid>
          {/* Campo de fecha de nacimiento */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="fechaNacimiento"
              label="Fecha de Nacimiento"
              type="date"
              variant="outlined"
              value={fechaNacimiento}
              onChange={handleFormattedFechaChange}
              placeholder="Ej: ejemplo@correo.com"
              required
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ mt: 1, textAlign: "center" }}>
              <Typography variant="h6" fontSize={"140%"}>
                ¿Tienes una empresa?
              </Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tieneEmpresa}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Ingresa los datos de tu empresa para pagar con factura y RUT empresarial haciendo click en el recuadro"
              />
            </Box>
          </Grid>
          {/* Datos de la empresa (solo si el usuario lo requiere) */}
          {tieneEmpresa && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="rutEmpresa"
                  label="RUT Empresa"
                  variant="outlined"
                  value={rutEmpresa}
                  onChange={(e) => setRutEmpresa(e.target.value)}
                  placeholder="Ej: 12.345.678-9"
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="razonSocial"
                  label="Razón Social"
                  variant="outlined"
                  value={razonSocial}
                  onChange={(e) => setRazonSocial(e.target.value)}
                  placeholder="Ej: Mi Empresa S.A."
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="giro"
                  label="Giro"
                  variant="outlined"
                  value={giro}
                  onChange={(e) => setGiro(e.target.value)}
                  placeholder="Ingrese el giro de la empresa"
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="direccionEmpresa"
                  label="Dirección Empresa"
                  variant="outlined"
                  value={direccionEmpresa}
                  onChange={(e) => setDireccionEmpresa(e.target.value)}
                  placeholder="Ej: Av. Siempre Viva 742"
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
            </>
          )}

          {/* Sección de Términos y Condiciones */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 0,
              p: 2,
              borderRadius: "10px",
              boxShadow: 2,
              width: "100%",
              maxWidth: "1500px",
              backgroundColor: "#fff",
              height: "auto",
              mt: { xs: "1.5em", sm: "2.5em", md: "1.5em" },
            }}
            id="boxTerminosYCondiciones"
            className={"terminosYCondicionesBox"}
          >
            {/* Imagen representativa de los términos */}
            <Box sx={{ mr: 4, ml: { xs: 1, sm: 2, md: 3 } }}>
              <img
                src={fotoBeneficios}
                alt="Icono Términos"
                style={{
                  width: "5.5em",
                  height: "5.5em",
                  borderRadius: "80%",
                }}
              />
            </Box>

            {/* Texto que explica los términos */}
            <Box sx={{ width: "100%" }} id="boxTerminosYCondiciones">
              <Typography variant="body2" sx={{ mb: 0 }} id="terminosYCondiciones">
                <p>
                  Onloop respeta la Ley 19.496 sobre Protección de los Derechos
                  de los Consumidores y nos adherimos al Código de Buenas
                  Prácticas para el Comercio Electrónico de la Cámara de
                  Comercio de Santiago.
                </p>
              </Typography>
            </Box>
          </Box>

          {/* Sección de Política de cambios y devoluciones */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              p: 2,
              borderRadius: "10px",
              boxShadow: 2,
              backgroundColor: "#fff",
              height: "auto",
              mt: { xs: "1.5em", sm: "2.5em", md: "2em" },
            }}
            id="boxTerminosYCondiciones"
            className={"terminosYCondicionesBox"}
          >
            {/* Imagen representativa de la política */}
            <Box sx={{ mr: 4, ml: { xs: 1, sm: 2, md: 3 } }}>
              <img
                src={fotoBeneficios}
                alt="Icono Términos"
                style={{
                  width: "6em",
                  height: "6em",
                  borderRadius: "80%",
                }}
              />
            </Box>

            {/* Texto que explica la política */}
            <Box sx={{ width: "100%" }}>
              <Typography variant="body2" sx={{ mb: 1 }} id="terminosYCondiciones">
                <p>
                  Al registrarte en nuestro sitio web, declaras estar de acuerdo
                  con nuestros{" "}
                  <Link
                    href="/terminos"
                    target="_blank"
                    id="linkTerminos"
                    underline="hover"
                    sx={{ color: "#0F00A7" }}
                  >
                    Términos y condiciones de los productos y servicios
                  </Link>{" "}
                  y nuestra{" "}
                  <Link
                    href="/politica"
                    target="_blank"
                    id="linkTerminos"
                    underline="hover"
                    sx={{ color: "#0F00A7" }}
                  >
                    Política de cambios y devoluciones
                  </Link>
                  .
                </p>
              </Typography>
            </Box>
          </Box>

          {/* Checkbox para confirmar que se han leído y aceptado los términos */}
          <Grid item xs={12} id="gridTerminosYCondiciones">
            <FormControlLabel
              control={
                <Checkbox
                  checked={leidoTerminos}
                  onChange={(e) => setLeidoTerminos(e.target.checked)}
                  color="primary"
                  required
                />
              }
              label="He leído los términos y condiciones (obligatorio)"
            />
          </Grid>
        </Grid>
      </Box>

      {/* Componente ReCAPTCHA para prevenir registros automatizados */}
      <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lf-9FQqAAAAAPpx7_0rhH8lm1tmjAtm8oeyk32g"
          onChange={handleRecaptchaChange}
        />
      </Box>

      {/* Botón de registro */}
      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleRegister}
          disabled={!isFormValid}
        >
          Registrar Cuenta
        </Button>
      </Box>
      {message && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography variant="body1">{message}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default Register;