import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography } from '@mui/material';

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
};

const CarruselProductos = ({ productosRelacionados }) => {
  // Si aún no hay datos, no renderiza nada (el padre se encarga del loading)
  if (!productosRelacionados) {
    return null;
  }

  // Si se recibió un arreglo vacío, muestra un mensaje
  if (productosRelacionados.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ mt: 2 }}>
        No hay productos relacionados.
      </Typography>
    );
  }

  // Mezcla los productos de forma aleatoria
  const randomProductos = [...productosRelacionados].sort(() => Math.random() - 0.5);

  // Función para formatear el precio en CLP
  const formatPrice = (price) => {
    const numericPrice = Number(price);
    return isNaN(numericPrice)
      ? 'Precio no disponible'
      : numericPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  };

  // Función para convertir el título a slug para la URL
  const titleToSlug = (title) => title.toLowerCase().replace(/ /g, '-');

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Carousel responsive={responsive}>
        {randomProductos.map((item) => (
          <Box key={item.id} sx={{ padding: '10px' }}>
            <Box
              onClick={() =>
                (window.location.href = `/producto/${titleToSlug(item.titulo)}/${item.id}`)
              }
              style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '250px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  src={item.imagen}
                  alt={item.titulo}
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  onError={(e) => {
                    e.target.src = 'https://onloop.cl/images/Imagen_no_disponible.jpg';
                  }}
                />
              </Box>
              <Typography variant="body1" align="center">
                {item.titulo}
              </Typography>
              <Typography variant="body2" align="center" color="primary">
                {formatPrice(item.precio)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default CarruselProductos;