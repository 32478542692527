import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import fotoBeneficios from "../../Image/foto-beneficio.jpg";
import InventoryIcon from "@mui/icons-material/Inventory";
import HandshakeIcon from "@mui/icons-material/Handshake";

const Beneficios = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const beneficios = [
    {
      id: 1,
      icon: <InventoryIcon sx={{ fontSize: 40, color: "#b63232", mr: 2 }} />,
      titulo: "En las manos del repartidor en 24 horas o menos",
      descripcion:
        "A menos que te indiquemos lo contrario, si completas tu pedido en horario hábil, lo procesaremos lo antes posible para entregárselo al courier en menos de 24 horas. Así acortamos tu espera y entregamos lo antes posible.",
    },
    {
      id: 2,
      icon: (
        <LocalShippingIcon sx={{ fontSize: 40, color: "#b63232", mr: 2 }} />
      ),
      titulo: "Envíos a todo Chile y punto",
      descripcion:
        "Santiago no es Chile. Además de despachar desde nuestras bodegas en 24 horas o menos, nos aseguramos de que nuestros couriers cubran la mayor parte de nuestro país, para que disfrutes nuestros productos donde estés.",
    },
    {
      id: 3,
      icon: <HandshakeIcon sx={{ fontSize: 40, color: "#b63232", mr: 2 }} />,
      titulo: "Respaldo y confianza garantizada",
      descripcion:
        "Más de 12 años de experiencia acompañan nuestro crecimiento, para acompañar el tuyo. Onloop es miembro de la Cámara de Comercio de Santiago (CCS) y nuestras transacciones se completan mediante Webpay, Mercado Pago o Transferencia electrónica, asegurando la seguridad y confianza de cada transacción.",
    },
  ];

  return (
    <Grid container spacing={3} alignItems="center" sx={{ px: 3, py: 5 }}>
      {/* Imagen */}
      <Grid item xs={12} md={6} order={isMobile ? 2 : 1}>
        <Box
          component="img"
          src={fotoBeneficios}
          alt="foto beneficios"
          sx={{
            width: "100%",
            height: isMobile ? "auto" : "400px",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      </Grid>

      {/* Sección de Beneficios */}
      <Grid item xs={12} md={6} order={isMobile ? 1 : 2}>
        <Box>
          {beneficios.map((beneficio) => (
            <Box
              key={beneficio.id}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 3,
                p: 2,
                borderRadius: "10px",
                boxShadow: 2,
                backgroundColor: "#fff",
              }}
              className={"beneficiosBox"}
            >
              {beneficio.icon}
              <Box>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    mb: 1,
                    fontFamily: "Outfit, sans-serif",
                    fontWeight: 700,
                  }}
                >
                  {beneficio.titulo}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontFamily: "Outfit, sans-serif", fontWeight: 400 }}
                >
                  {beneficio.descripcion}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Beneficios;
