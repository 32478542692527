import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import SwitchButton from "./SwitchButton"; // Modo oscuro
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import IsotipoOnLoop from "../Image/Footer/ONLOOP_ISOTIPO.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import carritoImg from "../Image/CARRITO.svg";
import {
  Badge,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CartContext } from "../Context/CardContext";
import styled from "styled-components";
import Onloop from "../Image/ONLOOPLOGO.svg";
import Carrito from "./Compra/Carito";
import { useDarkMode } from "../Context/DarkModeContext";
import SearchIcon from "@mui/icons-material/Search";
import "./Styles/SinComponente/Navbar.css";

const Container = styled.div`
  /* Contenedor principal del Navbar */
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 2rem;
    background: #ffffff;
    transition: top 0.7s ease;
  }

  /* Sticky */
  .nav.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-bottom: 5px solid #b63232;
    transition: 0.1s ease;
  }

  /* Grupo Izquierdo: Hamburger y Logo */
  .nav-left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  /* Grupo Central: Menú de navegación */
  .nav-center {
    display: flex;
    gap: 1rem;
  }

  /* Grupo Derecho: Búsqueda y Carrito */
  .nav-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  /* Logo */
  .logo-button {
    height: 55px;
    width: 70px;
    background-image: url(${Onloop});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-decoration: none;
    color: inherit;
    filter: invert(1);
  }

  /* Menú de navegación */
  .menu-item {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    font-family: outfit;
    padding: 0px 10px 4px 10px;
    border-bottom: 3px solid transparent;
    transition: border 0.3s;
  }

  .menu-item:hover {
    border-bottom: 3px solid #b63232;
  }

  /* Iconos */
  .icon-button {
    padding: 8px;
    transition: background-color 0.3s ease;
  }
  .icon-button:hover {
    background-color: #b63232;
  }
  .carrito-img {
    width: 30px;
    height: 30px;
    filter: invert(1);
    transition: filter 0.3s ease;
  }
  .icon-button:hover .carrito-img {
    filter: invert(0);
  }

  /* Hamburger: Visible solo en mobile */
  .hamburger {
    display: none;
    cursor: pointer;
  }

  /* Ajustes responsivos */
  @media (max-width: 768px) {
    /* Se oculta el menú central en mobile */
    .nav-center {
      display: none;
    }
    /* Se apilan todos los contenedores en una sola línea,
       pero luego reordenamos con 'order' */
    .nav {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: center;
      padding: 12px 1rem;
    }
    /* Mostramos el botón hamburguesa */
    .hamburger {
      display: block;
    }
    /* Quitamos el ancho fijo en nav-left y nav-right
       y dejamos que los elementos se dispongan en una sola fila */
    .nav-left,
    .nav-right {
      display: contents; /* Para que no sumen contenedores extra */
    }
    /* Reordenamos los elementos con 'order' 
       (solo aplica en mobile) */
    .hamburger {
      order: 1;
    }
    .logo-button {
      order: 2;
      width: 130px; /* Ajusta el tamaño del logo en mobile */
    }
    .switch-button {
      order: 3;
    }
    .search-button {
      order: 4;
    }
    .cart-button {
      order: 5;
    }
  }
`;

const Navbar = ({ searchText, setSearchText }) => {
  const theme = useTheme();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const { isDarkMode } = useDarkMode();
  const { cantidadEnCarrito } = useContext(CartContext);
  const inputRef = useRef(null);

  // Actualiza el modo oscuro en el body
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  // Hace que el navbar sea sticky al hacer scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Foco en el input al abrir el diálogo de búsqueda
  useEffect(() => {
    if (openDialog) {
      setTimeout(() => inputRef.current?.focus(), 100);
    }
  }, [openDialog]);

  const toggleDrawer = (newOpen) => () => setIsOpen(newOpen);
  const toggleMenuDrawer = (newOpen) => () => setMenuOpen(newOpen);

  const isConfirmationPage = location.pathname === "/confirmacion";
  const isProductosPage = location.pathname === "/productos";

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      window.location.href = `/productos?search=${value}`;
    }
  };

  const CarritoComponent = (
    <Box sx={{ width: 250 }} role="presentation">
      <Grid container justifyContent="space-between" alignItems="center" sx={{ p: "1.3rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.5rem",
            fontFamily: "Outfit, sans-serif",
            fontWeight: "bold",
          }}
        >
          Tu carro
        </Typography>
        <Button onClick={toggleDrawer(false)} sx={{ color: "black" }}>
          <CloseIcon />
        </Button>
      </Grid>
      <Divider />
      <Carrito toggleDrawer={toggleDrawer} />
    </Box>
  );

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)}>
      <List>
        {[
          { text: "Inicio", href: "/" },
          { text: "Productos", href: "/productos" },
          { text: "Blog", href: "/blog" },
          { text: "Nosotros", href: "/nosotros" },
          { text: "Contacto", href: "/contacto" },
        ].map(({ text, href }) => (
          <ListItem key={href} disablePadding>
            <ListItemButton onClick={() => (window.location.href = href)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Divider />
      <div className="contenedorContactanosHamburger">
        <img src={IsotipoOnLoop} className="isotipoHamburgerOnLoop" alt="OnLoop Logo" height="100" />
        <Typography variant="h6" gutterBottom className="contactanosHamburger" sx={{ mt: 1, fontFamily: "Outfit, sans-serif", fontWeight: 700 }}>
          Contáctanos
        </Typography>
        <Typography variant="body2" className="infoContactoHamburger" sx={{ fontFamily: "Outfit, sans-serif", fontWeight: 400 }}>
          <span>contacto@onloop.cl</span>
          <br />
          <span>+56929587626</span>
        </Typography>
      </div>
    </Box>
  );

  return (
    <Container>
      <nav className={`nav ${isSticky ? "sticky" : ""}`}>
        {isConfirmationPage ? (
          <>
            <Link onClick={() => (window.location.href = "/")} className="logo-button" />
            <SwitchButton />
            <Button
              variant="outlined"
              onClick={() => (window.location.href = "/")}
              sx={{
                backgroundColor: "#ff4d4d",
                color: "white",
                borderColor: "#ff4d4d",
                "&:hover": { backgroundColor: "#e03e3e", borderColor: "#e03e3e" },
              }}
            >
              Seguir comprando
            </Button>
          </>
        ) : (
          <>
            {/* Grupo Izquierdo: Hamburger, Logo y Switch */}
            <div className="nav-left">
              <div className="hamburger" onClick={toggleMenuDrawer(true)}>
                <MenuIcon style={{ color: "black" }} />
              </div>
              <Link onClick={() => (window.location.href = "/")} className="logo-button" />
              {/* Envolvemos el SwitchButton en un div con clase "switch-button"
                  para poder reordenarlo en mobile con 'order' */}
              <div className="switch-button">
                <SwitchButton />
              </div>
            </div>
            {/* Grupo Central: Menú (visible solo en desktop) */}
            <div className="nav-center">
              <Link onClick={() => (window.location.href = "/")} className="menu-item">
                Inicio
              </Link>
              <Link to="/productos" className="menu-item">
                Productos
              </Link>
              <Link to="/blog" className="menu-item">
                Blog
              </Link>
              <Link to="/nosotros" className="menu-item">
                Nosotros
              </Link>
              <Link to="/contacto" className="menu-item">
                Contacto
              </Link>
            </div>
            {/* Grupo Derecho: Búsqueda y Carrito */}
            <div className="nav-right">
              <IconButton
                onClick={() => setOpenDialog(true)}
                /* Agregamos la clase "search-button" para reordenar en mobile */
                className="search-button"
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: "#b63232" },
                }}
              >
                <SearchIcon sx={{ color: "black", "&:hover": { color: "#ffffff" } }} />
              </IconButton>
              <IconButton
                onClick={toggleDrawer(true)}
                aria-label="Carrito"
                className="icon-button cart-button"
              >
                <Badge
                  badgeContent={cantidadEnCarrito()}
                  sx={{ "& .MuiBadge-badge": { backgroundColor: "#333", color: "white" } }}
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <img src={carritoImg} alt="Carrito" className="carrito-img" />
                </Badge>
              </IconButton>
            </div>
            {/* Dialog de búsqueda */}
            <Dialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              sx={{
                "& .MuiDialog-paper": {
                  position: "absolute",
                  top: "15%",
                  transform: "translateY(-20%)",
                  width: "90%",
                  maxWidth: "600px",
                },
              }}
            >
              <DialogContent>
                <Box
                  component="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const searchValue = e.target[0].value;
                    handleSearch(searchValue);
                    setOpenDialog(false);
                  }}
                >
                  {isProductosPage ? (
                    <TextField
                      id="buscar-productos"
                      placeholder="Buscar productos..."
                      variant="standard"
                      required
                      fullWidth
                      inputRef={inputRef}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      sx={{ width: "100%", maxWidth: "600px", margin: "auto" }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          fontSize: "16px",
                          padding: "10px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                const form = e.target.closest("form");
                                const searchValue = form ? form[0].value : "";
                                handleSearch(searchValue);
                              }}
                              sx={{
                                backgroundColor: "#b63232",
                                borderRadius: "50%",
                                padding: "10px",
                                color: "#ffffff",
                                "&:hover": { backgroundColor: "#b63232" },
                              }}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      id="buscar-productos"
                      placeholder="Buscar productos..."
                      variant="standard"
                      required
                      fullWidth
                      inputRef={inputRef}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          fontSize: "16px",
                          padding: "10px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                const form = e.target.closest("form");
                                const searchValue = form ? form[0].value : "";
                                handleSearch(searchValue);
                                setOpenDialog(false);
                              }}
                              sx={{
                                backgroundColor: "#b63232",
                                borderRadius: "50%",
                                padding: "10px",
                                color: "#ffffff",
                                "&:hover": { backgroundColor: "#b63232" },
                              }}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Box>
              </DialogContent>
            </Dialog>
            {/* Drawer del Carrito */}
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
              {CarritoComponent}
            </Drawer>
            {/* Drawer del Menú Hamburguesa */}
            <Drawer anchor="left" open={menuOpen} onClose={toggleMenuDrawer(false)}>
              {DrawerList}
            </Drawer>
          </>
        )}
      </nav>
    </Container>
  );
};

export default Navbar;