import React from "react";
import {
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import '../Styles/Productos/SearchBar.css';

const CategoryToggleButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#000",
  border: "1px solid #000",
  boxShadow: "none",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  minWidth: "14rem",
  transition: "background-color 0.3s ease, transform 0.2s ease",
  fontFamily: "Outfit, sans-serif",
  fontWeight: 400,
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#f0f0f0",
    transform: "scale(1.02)",
  },
  "&:active": {
    transform: "scale(0.98)",
  },
}));

const SearchBar = ({
  searchText,
  setSearchText,
  sortOption,
  setSortOption,
  hideCategories,
  setHideCategories,
}) => {
  var timer = null;
  const urlParams = new URLSearchParams(window.location.search);

  const text = urlParams.get("search") || "";
  const sortParam = urlParams.get("sort") || "default"; // Obtener sort desde la URL

  if (sortParam && sortParam !== sortOption) {
    setSortOption(sortParam); // Asegurar que el estado se actualice con la URL
  }

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);

    // Actualizar el parámetro en la URL
    const url = new URL(window.location);
    url.searchParams.set("sort", newSortOption);
    window.history.pushState({}, "", url);
  };

  return (
    <Box
      sx={{
        margin: "2rem auto",
        padding: "1.5rem",
        border: "1px solid #ddd",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        maxWidth: "1000px",
        width: "90%",
      }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            "@media (max-width:1000px)": {
              flexBasis: "100%",
              maxWidth: "100%",
            },
          }}
        >
          <TextField
            id="buscar-productos"
            label="Buscar Productos"
            placeholder="Buscar..."
            variant="outlined"
            defaultValue={text}
            onChange={(e) => {
              urlParams.set("search", e.target.value);
              window.history.pushState(
                {},
                "",
                `${window.location.pathname}?${urlParams}`
              );

              clearTimeout(timer);
              timer = setTimeout(() => {
                setSearchText(e.target.value);
              }, 600);
            }}
            fullWidth
            required
            sx={{
              "& .MuiInputBase-root": {
                height: "50px",
              },
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            "@media (max-width:1000px)": {
              flexBasis: "100%",
              maxWidth: "100%",
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <CategoryToggleButton
            onClick={() => setHideCategories(!hideCategories)}
            className={"categoryToggleButton"}
          >
            {hideCategories ? "Mostrar Categorías" : "Ocultar Categorías"}
          </CategoryToggleButton>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            "@media (max-width:1000px)": {
              flexBasis: "100%",
              maxWidth: "100%",
            },
          }}
        >
          <FormControl variant="outlined" fullWidth sx={{ minWidth: "160px" }}>
            <InputLabel id="ordenar-label">Ordenar por</InputLabel>
            <Select
              labelId="ordenar-label"
              label="Ordenar por"
              className="ordenarLabel"
              value={sortOption || "default"}
              onChange={(e) => handleSortChange(e.target.value)}
              required
              sx={{
                height: "50px",
              }}
            >
              <MenuItem value="default">Seleccione</MenuItem>
              <MenuItem value="tituloASC">Nombre (A-Z)</MenuItem>
              <MenuItem value="tituloDESC">Nombre (Z-A)</MenuItem>
              <MenuItem value="precioASC">Precio (menor a mayor)</MenuItem>
              <MenuItem value="precioDESC">Precio (mayor a menor)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchBar;
