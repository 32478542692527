import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import fotoBlog from '../Image/Banners/blogbanner.jpg';
// import image0 from '../Image/Blog/test1.jpg';
import image1 from '../Image/Blog/1.jpeg';
import image2 from '../Image/Blog/2.jpeg';
import image3 from '../Image/Blog/3.jpg';
import image4 from '../Image/Blog/4.jpg';
import image5 from '../Image/Blog/5.jpeg';
import './Styles/SinComponente/Blog.css';

// Estilos con Styled Components
const Container = styled.div`
  .overlay-container-blog {
    position: relative;
    width: 100%;
    height: 250px;
  }

  .foto-blog {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay h1 {
    font-size: 2.5rem;
    color: white;
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
  }

  .blog-contenedor {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 980px;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .tarjeta-blog {
    border: 1px solid rgb(228, 228, 228);
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 5px 7px 8px -0px rgb(189, 189, 189);
  }

  .contenido-tarjeta {
    padding: 20px;
  }

  .contenido-tarjeta h3 {
    padding-bottom: 20px;
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
  }

  .contenido-tarjeta p {
    font-family: "Inter", serif;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
  }

  .contenido-tarjeta a {
    display: flex;
    font-size: 1.25rem;
    font-family: 'Outfit', sans-serif;
    text-decoration: none;
    padding: 8px 20px;
    background-color: #B63232;
    justify-content: center;
    border-radius: 10px;
    margin-top: 20px;
    color: white;
  }

  .tarjeta-blog img {
    width: 299px;
    height: 200px;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

// Datos del blog
const blogs = [
  { slug: "sillas-ergonomicas", title: "Sillas Ergonómicas: Comodidad y Durabilidad", text: "Elegir sillas cómodas y ergonómicas es crucial para...", image: image1 },
  { slug: "escritorios-modernos", title: "Escritorios Modernos: Escritorios que Inspiran", text: "Encuentra el escritorio perfecto para tu hogar u oficina...", image: image2 },
  { slug: "lockers-metal", title: "Lockers de Metal: Estilo y Funcionalidad para Tu Espacio", text: "En la búsqueda de soluciones prácticas para la organización y...", image: image3 },
  { slug: "simulando-profesiones", title: "Simulando Profesiones: Juguetes Educativos para Niños", text: "Los juguetes desempeñan un papel esencial en el desarrollo infantil...", image: image4 },
  { slug: "autos-control-remoto", title: "Autos a Control Remoto: Tecnología, Potencia y Entretenimiento", text: "¡Descubre la diversión de los autos a control remoto!...", image: image5 },
  // { slug: "ejercicio-aire-libre", title: "Beneficios del Ejercicio al Aire Libre", text: "Cómo aprovechar la naturaleza para mejorar tu bienestarLos beneficios del ejercicio al aire libre: cómo...", image: image0 }
];

const Blog = () => {
  return (
    <Container>
      <div className="overlay-container-blog">
        <img src={fotoBlog} className="foto-blog" alt="Blog Banner" />
        <div className="overlay">
          <h1   style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '50px',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}>Blog
          </h1>
        </div>
      </div>

      <div className='blog-contenedor'>

        {blogs.map((blog) => (
          <TarjetaBlog key={blog.slug} image={blog.image} title={blog.title} description={blog.text} link={`/blog/${blog.slug}`} />
        ))}
      </div>
    </Container>
  );
};

export default Blog;

// Componente para las tarjetas de blog
const TarjetaBlog = ({ image, title, description, link }) => {
  return (
    <div className='tarjeta-blog'>
      <img src={image} alt={title} />
      <div className='contenido-tarjeta'>
        <h3 style={{height:"5rem", textOverflow:"ellipsis", overflow:'hidden'}}>{title}</h3>
        <p style={{height:"4rem"}}>{description}</p>
        <Link to={link} className="boton">Ver más</Link>
      </div>
    </div>
  );
};
