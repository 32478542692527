import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import carritoImg from '../../Image/CARRITO.svg';

const ItemCount = ({ 
  cantidad, 
  handleRestar, 
  handleSumar, 
  handleAgregar, 
  stock, 
  costeAdicional, 
  categoria, 
  pdfFileUrl
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        {/* Contenedor de botones de suma/resta */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ff4d4d',
              '&:hover': { backgroundColor: '#e03e3e' },
              padding: '4px 15px',
              fontSize: '0.875rem',
              minWidth: '35px'
            }}
            onClick={handleRestar}
          >
            -
          </Button>
          <Typography variant="body1" sx={{ fontSize: '1.5rem' }}>
            {cantidad}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ff4d4d',
              '&:hover': { backgroundColor: '#e03e3e' },
              padding: '4px 15px',
              fontSize: '0.875rem',
              minWidth: '35px'
            }}
            onClick={handleSumar}
          >
            +
          </Button>
        </Box>

        {/* Contenedor de botones de acción */}
        <Box sx={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ff4d4d',
              '&:hover': { backgroundColor: '#e03e3e' },
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
            onClick={handleAgregar}
          >
            Agregar al carrito
            <img
              src={carritoImg}
              alt="Carrito"
              style={{ width: '20px', height: '20px' }}
            />
          </Button>

          {/* Botón para descargar PDF (se muestra solo si existe el archivo) */}
          {pdfFileUrl && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ff4d4d',
                '&:hover': { backgroundColor: '#e03e3e' },
                textTransform: 'none'
              }}
              component="a"
              href={pdfFileUrl}
              download
            >
              Descargar ficha técnica
            </Button>
          )}
        </Box>
      </Box>

      {stock < 10 && (
        <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
          Últimas unidades ({stock} disponibles)
        </Typography>
      )}
      {costeAdicional > 0 && (
        <Typography
          variant="body2"
          color="warning"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            marginTop: '0.5rem',
            textAlign: 'justify',
            textJustify: 'inter-word'
          }}
        >
          * El envío de 2 o más unidades posee un recargo adicional calculado al momento de la compra.
        </Typography>
      )}
      {((categoria !== 'Juguetes' && categoria !== 'Accesorios para mascotas') || costeAdicional > 0) && (
        <Typography
          variant="body2"
          color="warning"
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            marginTop: '0.5rem',
            textAlign: 'justify',
            textJustify: 'inter-word'
          }}
        >
          * El envío de 3000 solo aplica a las categorías: accesorios de mascotas y juguetes.
        </Typography>
      )}
    </Box>
  );
};

export default ItemCount;