import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useDarkMode } from "../../../Context/DarkModeContext"; 

function Cancelado() {
  const { isDarkMode } = useDarkMode(); //  el estado del modo oscuro

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]); // Este useEffect solo se ejecutará cuando `isDarkMode` cambie

  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el estado de pago es 'cancelado'
    const paymentStatus = sessionStorage.getItem("payment_status");

    if (paymentStatus !== "cancelled") {
      // Redirigir al usuario a la página principal si no se ha cancelado el pago
      /* navigate('/') */
    }

    // Eliminar el estado después de la visualización
    sessionStorage.removeItem("payment_status");

    // Redirigir a la página principal después de 5 segundos
    const timer = setTimeout(() => {
      /* navigate('/') */
    }, 5000);

    // Limpiar el temporizador cuando el componente se desmonte
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "2rem" }}>
      <Typography variant="h5" sx={{color: isDarkMode ? "white" : "black" }}>
        ¡Compra cancelada!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        El proceso de pago fue cancelado. No se realizó ningún cargo.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, color: "text.secondary" }}>
        Será redirigido a la página principal en unos momentos...
      </Typography>
    </div>
  );
}

export default Cancelado;
