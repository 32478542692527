import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const ProductosVariantes = ({ variantes }) => {
  if (!variantes || variantes.length === 0) return null;

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Typography variant="h6" sx={{ marginBottom: '1rem', fontFamily: 'Outfit, sans-serif', fontWeight: 700, }}>
        Variantes del producto:
      </Typography>
      {variantes.map((variant) => (
        <Typography key={variant.id} sx={{ marginBottom: '1rem', fontFamily: 'Outfit, sans-serif', fontWeight: 400, }}>
          <Link
            href={variant.link}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            {variant.nombre}
          </Link>
        </Typography>
      ))}
    </Box>
  );
};

export default ProductosVariantes;
