import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import UserContext from "../../../Context/UserContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useDarkMode } from "../../../Context/DarkModeContext";
import { validarRut } from "../../../Utils/validarRut";

/**
 * Componente principal para la edición de la cuenta del usuario.
 * Permite actualizar datos personales, gestionar direcciones de envío y eliminar la cuenta.
 */
const MyAccount = () => {
  // Obtiene el estado del modo oscuro a través del contexto
  const { isDarkMode } = useDarkMode();

  // Estado para controlar la apertura del diálogo de confirmación para eliminar la cuenta
  const [openDialog, setOpenDialog] = useState(false);

  // Configuración de react-hook-form para el manejo y validación del formulario
  const {
    // register,
    // handleSubmit,
    // formState: { errors, isValid },
    // reset,
    // setValue,
    getValues,
  } = useForm({
    mode: "onChange", // Validación en cada cambio de campo
  });

  // Efecto para aplicar o remover la clase "dark-mode" en el body según el estado del modo oscuro
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  // Obtiene el usuario actual y la función para actualizarlo desde el contexto
  const { user, setUser } = useContext(UserContext);

  // Al cargar el componente, si existe usuario, se inicializan los estados correspondientes
  useEffect(() => {
    if (user) {
      setUsuario(user.usuario || ""); // Maneja posibles valores nulos
      setTelefono(user.telefono || "");
      setCorreo(user.correo || "");
      console.log("Datos del usuario obtenidos:", user); // Depuración
    }
  }, [user]);

  // Estados para almacenar la información del usuario
  const [usuario, setUsuario] = useState(user ? user.usuario : "");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Estados para controlar errores de validación en teléfono y correo
  const [telefonoInvalido, setTelefonoInvalido] = useState(false);
  const [correoInvalido, setCorreoInvalido] = useState(false);
  const [message, setMessage] = useState("");

  // Referencia para el componente ReCAPTCHA
  const recaptchaRef = useRef(null);

  // Estado para la validación del RUT (en la dirección de envío)
  const [rutInvalido, setRutInvalido] = React.useState(false);

  // Mensajes de depuración en consola
  console.log("Valor de user:", user);
  console.log("Valor de usuario:", usuario);

  // Estados para gestionar las direcciones de envío
  const [direcciones, setDirecciones] = useState([]);
  const [direccion, setDireccion] = useState({
    tipoDomicilio: "",
    nombre: "",
    rutReceptor: "",
    direccion: "",
    observaciones: "",
    favorita: false,
  });

  // Estados para manejar la edición de direcciones
  const [direccionEditando, setDireccionEditando] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  /**
   * Agrega la dirección ingresada a la lista de direcciones y reinicia el formulario.
   */
  const handleAddDireccion = () => {
    setDirecciones((prev) => [...prev, direccion]);
    // Reinicia el estado de dirección para permitir agregar otra nueva
    setDireccion({
      tipoDomicilio: "",
      nombre: "",
      rutReceptor: "",
      direccion: "",
      observaciones: "",
      favorita: false,
    });
  };

  /**
   * Marca la dirección seleccionada como favorita y desmarca las demás.
   * @param {number} index Índice de la dirección a marcar como favorita.
   */
  const handleSetFavorita = (index) => {
    setDirecciones((prev) =>
      prev.map((item, idx) =>
        idx === index
          ? { ...item, favorita: true }
          : { ...item, favorita: false }
      )
    );
  };

  /**
   * Inicia el proceso de edición de una dirección.
   * @param {number} index Índice de la dirección a editar.
   */
  const handleEditDireccion = (index) => {
    setDireccionEditando({ ...direcciones[index], index });
    setOpenEditDialog(true);
  };

  /**
   * Guarda los cambios realizados en la dirección editada.
   */
  const handleSaveDireccion = () => {
    setDirecciones((prev) =>
      prev.map((item, idx) =>
        idx === direccionEditando.index ? { ...direccionEditando } : item
      )
    );
    setOpenEditDialog(false);
  };

  /**
   * Maneja los cambios en los campos de la dirección.
   * @param {object} e Evento del input.
   */
  const handleChangeDireccion = (e) => {
    const { name, value } = e.target;
    setDireccion((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Elimina una dirección de la lista.
   * @param {number} index Índice de la dirección a eliminar.
   */
  const handleDeleteDireccion = (index) => {
    setDirecciones((prev) => prev.filter((_, idx) => idx !== index));
  };

  /**
   * Maneja y valida el cambio en el campo del teléfono, permitiendo solo hasta 8 dígitos.
   * @param {object} event Evento del input.
   */
  const handleFormattedTelefonoChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setTelefono(value);
      // Si la longitud es diferente de 8 dígitos se marca como inválido
      setTelefonoInvalido(value.length !== 8);
    }
  };

  /**
   * Formatea el RUT eliminando caracteres inválidos y agregando el guion antes del dígito verificador.
   * @param {string} rut RUT sin formatear.
   * @returns {string} RUT formateado.
   */
  const formatRut = (rut) => {
    if (!rut) return "";
    rut = rut.replace(/[^0-9kK]/g, ""); // Elimina caracteres que no sean números o 'k/K'
    rut = rut.replace(/k/g, "K"); // Asegura que la letra sea mayúscula
    return rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1)}` : rut;
  };

  /**
   * Maneja el cambio en el campo RUT, formateándolo y validándolo.
   * Actualiza el estado de la dirección con el RUT del receptor.
   * @param {object} event Evento del input.
   */
  const handleRutChange = (event) => {
    const formattedRut = formatRut(event.target.value);

    console.log("RUT ingresado:", formattedRut);
    console.log("Es válido?", validarRut(formattedRut));

    // Actualiza el RUT en el objeto dirección
    setDireccion((prev) => ({ ...prev, rutReceptor: formattedRut }));

    // Valida el RUT y actualiza el estado correspondiente
    if (validarRut(formattedRut)) {
      setRutInvalido(false);
    } else {
      setRutInvalido(true);
    }
  };

  /**
   * Maneja y valida el cambio en el campo del correo electrónico.
   * @param {object} event Evento del input.
   */
  const handleFormattedCorreoChange = (event) => {
    setCorreo(event.target.value);
    // Regex para validar el formato de correo
    const correoRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setCorreoInvalido(!correoRegex.test(event.target.value));
  };

  /**
   * Maneja el cambio del valor del ReCAPTCHA.
   * @param {string} value Valor devuelto por el componente ReCAPTCHA.
   */
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  /**
   * Actualiza la información de la cuenta del usuario.
   * Valida los campos requeridos y envía los datos al backend mediante axios.
   */
  const handleUpdate = async () => {
    // Verifica que todos los campos requeridos estén completos y sean válidos
    if (!usuario || !telefono || !correo || !recaptchaValue) {
      setMessage("Por favor completa todos los campos requeridos.");
      return;
    }
    if (telefonoInvalido) {
      setMessage("El teléfono debe tener 8 dígitos.");
      return;
    }
    if (correoInvalido) {
      setMessage("El correo no es válido.");
      return;
    }

    // Crea un objeto con los datos del usuario a enviar al backend
    const userData = {
      usuario,
      telefono,
      correo,
      direcciones,
      recaptchaValue,
    };

    // Validación adicional del RUT usando react-hook-form
    if (!validarRut(getValues("rut"))) {
      setRutInvalido(true);
      return;
    }
    if (userData.telefono.length !== 8) return;

    // Envío de datos al backend usando axios
    try {
      const response = await axios.post(
        "https://pruebas.onloop.cl/api/changeAccountData.php",
        userData
      );
      if (response.data.success) {
        setMessage("Datos actualizados correctamente.");
        setUser(userData);
      } else {
        setMessage(response.data.message || "Error al actualizar los datos.");
      }
    } catch (error) {
      setMessage("Error en la comunicación con el servidor.");
    }
  };

  /**
   * Elimina la cuenta del usuario mediante una petición al backend.
   */
  const handleDeleteAccount = async () => {
    try {
      const response = await axios.post(
        "https://pruebas.onloop.cl/api/deleteAccount.php"
      );
      if (response.data.success) {
        setMessage("Cuenta eliminada correctamente.");
      } else {
        setMessage(response.data.message || "Error al eliminar la cuenta.");
      }
    } catch (error) {
      setMessage("Error en la comunicación con el servidor.");
    }
    // Cierra el diálogo de confirmación de eliminación
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="md">
      {/* Encabezado de la sección */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h6" fontSize={"140%"}>
          Cambiar datos de la cuenta
        </Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* Sección para actualizar datos personales */}
          <Grid item xs={9}>
            {/* Campo para ingresar el nuevo nombre de usuario */}
            <TextField
              fullWidth
              id="usuario-label-MyAccount"
              label={`Nuevo nombre de usuario: ${usuario || "Cargando..."}`}
              variant="outlined"
              value={usuario}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
              onChange={(e) => setUsuario(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={9}>
            {/* Campo para ingresar el nuevo número de teléfono */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography sx={{ mr: 1 }}>+569</Typography>
              <TextField
                fullWidth
                id="telefono-label-MyAccount"
                label="Nuevo número de teléfono"
                variant="outlined"
                value={telefono}
                onChange={handleFormattedTelefonoChange}
                error={telefonoInvalido}
                InputLabelProps={{
                  sx: {
                    color: isDarkMode ? "white" : "black",
                    "&.Mui-focused": {
                      color: isDarkMode ? "lightgray" : "blue",
                    },
                  },
                }}
                helperText={
                  telefonoInvalido ? "El teléfono debe tener 8 dígitos" : ""
                }
                required
              />
            </Box>
          </Grid>
          {/* Campo para ingresar la nueva dirección de correo */}
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="correo-label-MyAccount"
              label="Nueva dirección de correo"
              variant="outlined"
              value={correo}
              onChange={handleFormattedCorreoChange}
              error={correoInvalido}
              InputLabelProps={{
                sx: {
                  color: isDarkMode ? "white" : "black",
                  "&.Mui-focused": {
                    color: isDarkMode ? "lightgray" : "blue",
                  },
                },
              }}
              helperText={
                correoInvalido ? "El correo no tiene un formato válido" : ""
              }
              required
            />
          </Grid>

          {/* Sección para la gestión de direcciones de envío */}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={9}>
              <Box sx={{ mt: 6, textAlign: "center" }}>
                <Typography variant="h6" fontSize={"140%"}>
                  Direcciones de envío
                </Typography>
              </Box>
              <Box sx={{ mt: 3, mb: 2, textAlign: "left" }}>
                <Typography variant="h7" fontSize={"110%"}>
                  Ingresa tus datos de envío preferidos en esta sección
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              {/* Selección del tipo de domicilio (Casa u Oficina) */}
              <FormControl fullWidth required sx={{ mb: 1 }}>
                <InputLabel id="tipo-domicilio-label">
                  Tipo de Domicilio
                </InputLabel>
                <Select
                  labelId="tipo-domicilio-label"
                  id="tipoDomicilio"
                  name="tipoDomicilio"
                  value={direccion.tipoDomicilio}
                  onChange={handleChangeDireccion}
                  label="Tipo de Domicilio"
                >
                  <MenuItem value="casa">Casa</MenuItem>
                  <MenuItem value="oficina">Oficina</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              {/* Campo para el nombre de la persona que recibe el envío */}
              <TextField
                fullWidth
                label="Nombre de la Persona que Recibe"
                name="nombre"
                value={direccion.nombre}
                onChange={handleChangeDireccion}
                required
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item xs={9}>
              {/* Campo para ingresar el RUT de la persona que recibe */}
              <TextField
                fullWidth
                label="RUT de la Persona que Recibe"
                name="rutReceptor"
                error={rutInvalido}
                helperText={rutInvalido ? "RUT no válido" : ""}
                value={direccion.rutReceptor}
                onChange={handleRutChange}
                required
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item xs={9}>
              {/* Campo para ingresar la dirección */}
              <TextField
                fullWidth
                label="Dirección"
                name="direccion"
                value={direccion.direccion}
                onChange={handleChangeDireccion}
                required
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item xs={9}>
              {/* Campo para observaciones adicionales */}
              <TextField
                fullWidth
                label="Observaciones (Ej: Reja café, dejar en conserjería)"
                name="observaciones"
                value={direccion.observaciones}
                onChange={handleChangeDireccion}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={9}>
              {/* Botón para agregar la dirección ingresada */}
              <Button
                variant="outlined"
                onClick={handleAddDireccion}
                disabled={rutInvalido} // Se deshabilita si el RUT es inválido
              >
                Agregar Dirección
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={9}>
            <Box sx={{ mt: 4 }}>
              {/* Se muestran las direcciones agregadas si existen */}
              {direcciones.length > 0 && (
                <Typography variant="h6" fontSize={"120%"}>
                  Direcciones Agregadas:
                </Typography>
              )}
              {direcciones.map((direccion, index) => (
                <Box key={index} sx={{ mb: 2, mt: 3 }}>
                  <Typography sx={{ mb: 1 }}>
                    <span style={{ fontWeight: 500, fontSize: "110%" }}>
                      {index + 1}){" "}
                    </span>
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 500, fontSize: "110%" }}>
                      Tipo de domicilio:{" "}
                    </span>
                    {direccion.tipoDomicilio.charAt(0).toUpperCase() +
                      direccion.tipoDomicilio.slice(1).toLowerCase()}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 500, fontSize: "110%" }}>
                      Nombre de la Persona que Recibe:{" "}
                    </span>
                    {direccion.nombre}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 500, fontSize: "110%" }}>
                      RUT de la Persona que Recibe:{" "}
                    </span>
                    {direccion.rutReceptor}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 500, fontSize: "110%" }}>
                      Dirección:{" "}
                    </span>
                    {direccion.direccion}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 500, fontSize: "110%" }}>
                      Observaciones:{" "}
                    </span>
                    {direccion.observaciones}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: 2,
                      mt: 1,
                      mb: 2,
                    }}
                  >
                    {/* Botón para marcar la dirección como favorita */}
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSetFavorita(index)}
                      sx={{
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      {direccion.favorita ? "Favorita" : "Marcar como Favorita"}
                    </Button>

                    {/* Botón para editar la dirección */}
                    <Button
                      sx={{
                        mt: 2,
                        mb: 2,
                      }}
                      color="warning"
                      variant="outlined"
                      onClick={() => handleEditDireccion(index)}
                    >
                      Editar Dirección
                    </Button>

                    {/* Diálogo para editar la dirección */}
                    <Dialog
                      open={openEditDialog}
                      onClose={() => setOpenEditDialog(false)}
                    >
                      <DialogTitle>Editar Dirección</DialogTitle>
                      <DialogContent>
                        {direccionEditando && (
                          <>
                            <FormControl fullWidth required sx={{ mb: 2 }}>
                              <InputLabel id="tipo-domicilio-edit-label">
                                Tipo de Domicilio
                              </InputLabel>
                              <Select
                                labelId="tipo-domicilio-edit-label"
                                id="tipoDomicilio"
                                name="tipoDomicilio"
                                value={direccionEditando.tipoDomicilio}
                                onChange={(e) =>
                                  setDireccionEditando((prev) => ({
                                    ...prev,
                                    tipoDomicilio: e.target.value,
                                  }))
                                }
                                label="Tipo de Domicilio"
                              >
                                <MenuItem value="casa">Casa</MenuItem>
                                <MenuItem value="oficina">Oficina</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              fullWidth
                              label="Nombre de la Persona que Recibe"
                              value={direccionEditando.nombre}
                              onChange={(e) =>
                                setDireccionEditando((prev) => ({
                                  ...prev,
                                  nombre: e.target.value,
                                }))
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="RUT de la Persona que Recibe"
                              value={direccionEditando.rutReceptor}
                              onChange={(e) =>
                                setDireccionEditando((prev) => ({
                                  ...prev,
                                  rutReceptor: e.target.value,
                                }))
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Dirección"
                              value={direccionEditando.direccion}
                              onChange={(e) =>
                                setDireccionEditando((prev) => ({
                                  ...prev,
                                  direccion: e.target.value,
                                }))
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              fullWidth
                              label="Observaciones"
                              value={direccionEditando.observaciones}
                              onChange={(e) =>
                                setDireccionEditando((prev) => ({
                                  ...prev,
                                  observaciones: e.target.value,
                                }))
                              }
                              sx={{ mb: 2 }}
                            />
                          </>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpenEditDialog(false)}
                          color="secondary"
                        >
                          Cancelar
                        </Button>
                        <Button
                          onClick={handleSaveDireccion}
                          color="primary"
                          variant="contained"
                        >
                          Guardar Cambios
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* Botón para eliminar la dirección */}
                    <Button
                      sx={{
                        mt: 2,
                        mb: 2,
                      }}
                      color="error"
                      variant="outlined"
                      onClick={() => handleDeleteDireccion(index)}
                    >
                      Eliminar Dirección
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          {/* Componente ReCAPTCHA para validar que el usuario no es un robot */}
          <Grid
            item
            xs={9}
            sx={{ display: "flex", justifyContent: "center", mt: 0, mb: 2 }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lf-9FQqAAAAAPpx7_0rhH8lm1tmjAtm8oeyk32g"
              onChange={handleRecaptchaChange}
            />
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              {/* Botón para actualizar la información personal */}
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleUpdate}
                sx={{ mt: 3, ml: 2 }}
              >
                Actualizar información personal
              </Button>
            </Grid>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                {/* Botón para abrir el diálogo y eliminar la cuenta */}
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  onClick={() => setOpenDialog(true)}
                  sx={{ mt: 2, ml: 2, marginLeft: "1.4em" }}
                >
                  Eliminar cuenta
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Muestra un mensaje de error o confirmación si existe */}
          {message && (
            <Grid item xs={9}>
              <Typography color="error">{message}</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* Diálogo para confirmar la eliminación de la cuenta */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar tu cuenta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteAccount} color="error" autoFocus>
            Sí, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyAccount;